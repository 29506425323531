import React, { useEffect } from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useListMediaByTenantQuery } from '../api/engage.api';
import { useLocale } from '../hooks/useLocale';
import collaborators from '../assets/text/collaboratorsConfig';
import style from './Home.module.css';
import i18n from '../i18n';
import MediaCard from '../components/MediaCard';

const CollaboratorsSection = () => (
  <Container>
    <Row>
      {collaborators.map((collaborator, index) => (
        <Col xs={4} key={index} className={style.collaboratorRow}>
          <img
            src={collaborator.img}
            alt={`Collaborator ${index + 1}`}
            className="img-fluid"
          />
        </Col>
      ))}
    </Row>
  </Container>
);

const Home = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const basePath = `/${locale.languageCode}`;

  const { data } = useListMediaByTenantQuery({});

  useEffect(() => {
    i18n.changeLanguage(locale.languageCode);
  }, [locale.languageCode]);

  return (
    <div>
      <div className={style.sectionContainer}>
        <div className={style.shakaPlayerContainer}>
          <video autoPlay muted loop className={style.responsiveVideo}>
            <source
              src="https://assets-global.website-files.com/6565cefb18efd42c6f89d756/6567ff0a81787b5d0ff4ed90_AM%20Teaser_1-transcode.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className={style.mobileContainer}>
          <h1 className={style.title}>{t('home_title1')}</h1>
          <h1 className={style.title}>{t('home_title2')}</h1>
          <div className={style.paragraphTextStyling}>
            <div>
              <p className={style.customParagraphStyling}>
                {t('homepagePara1')}
              </p>
              <p className={style.customParagraphStyling}>
                {t('homepagePara2')}
              </p>
              <p className={style.customParagraphStyling2}>
                {t('homepagePara3')}
              </p>
            </div>
            <p className={style.customParagraphStyling2}>
              {t('homepagePara4')}
            </p>
            <p className={style.customParagraphStyling2}>
              {t('homepagePara5')}
            </p>
            <p className={style.customParagraphStyling2}>
              {t('homepagePara6')}
            </p>
          </div>
        </div>
      </div>
      <div className={style.sectionContainerWorks}>
        <div>
          <h2 className={style.secondaryTitleWorks}>{t('title_works')}</h2>
        </div>
        <Row>
          {data?.body.map((item: any) => (
            <Col key={item.id} xs={12} sm={4}>
              <MediaCard item={item} />
            </Col>
          ))}
        </Row>
        <a href={`${basePath}/works`}>
          <Button className={style.viewAllBtn}>{t('btn_viewAll')}</Button>
        </a>
      </div>
      <div className={style.sectionContainer}>
        <div>
          <h2 className={style.secondaryTitleCollab}>
            {t('title_collaborators')}
          </h2>
        </div>
        <CollaboratorsSection />
      </div>
    </div>
  );
};

export default Home;
