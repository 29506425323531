import nazLogo from '../images/webclip.png';
import hisano from '../images/staff/staff_hisano.jpg';
import hondai from '../images/staff/staff_hondai.jpg';
import ikariya from '../images/staff/staff_ikariya.jpg';
import itou from '../images/staff/staff_itou.jpg';
import kuriyama from '../images/staff/staff_kuriyama.jpg';
import mataga from '../images/staff/staff_mataga.jpg';
import mita from '../images/staff/staff_mita.jpg';
import takagi from '../images/staff/staff_takagi.jpg';
import takigawa from '../images/staff/staff_takigawa.jpg';
import yoshida from '../images/staff/staff_yoshida.jpg';

const staffConfig = [
  {
    id: 1,
    img: `${nazLogo}`,
    name: 'Otaro Maijo',
    position: 'Novelist',
    involvement: [
      {
        anime_title: 'Opening for "Sabikui Bisco"',
        role: 'Storyboard',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Series Organization, Scriptwriter',
      },
      {
        anime_title: 'The Dragon Dentist',
        role: 'Original Work, Scriptwriter',
      },
    ],
  },
  {
    id: 2,
    img: `${ikariya}`,
    name: 'Atsushi Ikariya',
    position: 'Director, Animator',
    involvement: [
      {
        anime_title: 'Sabikui Bisco',
        role: 'Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Character Designer, Chief Animation Director, Storyboard Artist, Episode Directror, Animation Director',
      },
      {
        anime_title: "Fate/stay night: Heaven's Feel III. spring song",
        role: 'Character Designer',
      },
      {
        anime_title: "Fate/stay night: Heaven's Feel II. Lost Butterfly",
        role: 'Character Designer',
      },
      {
        anime_title: 'Attack on Titan Season 3',
        role: 'Key Animator',
      },
      {
        anime_title: "Fate/stay night: Heaven's Feel I. presage flower",
        role: 'Character Designer',
      },
      {
        anime_title: 'Busou Shinki',
        role: 'Character Designer',
      },
      {
        anime_title: 'Terra Formars: Revenge',
        role: 'Character Designer',
      },
      {
        anime_title: 'The Devil Is a Part-Timer!',
        role: 'Character Designer, Animation Director',
      },
      {
        anime_title: 'Evangelion: 3.0 You Can (Not) Redo',
        role: 'Key Animator',
      },
      {
        anime_title: 'Fate/Zero',
        role: 'Character Designer, Animation Director',
      },
      {
        anime_title: 'Evangelion: 2.0 You Can (Not) Advance',
        role: 'Key Animator',
      },
      {
        anime_title: 'Evangelion: 1.0 You Are (Not) Alone',
        role: 'Key Animator',
      },
      {
        anime_title: 'Code Geass: Lelouch of the Rebellion',
        role: 'Key Animator, Animation Director',
      },
      {
        anime_title: 'Pokémon 4Ever',
        role: 'In-Between Animator',
      },
      {
        anime_title: 'Pokémon 3 the Movie: Spell of the Unown',
        role: 'In-Between Animator',
      },
    ],
  },
  {
    id: 3,
    img: `${mataga}`,
    name: 'Daisuke Mataga',
    position: 'Director, Animator',
    involvement: [
      {
        anime_title: 'Sabikui Bisco',
        role: 'Assistant Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Main Animator, Storyboard, Episode Director, Animation Director',
      },
      {
        anime_title: 'Doraemon the Movie: Nobita\'s Treasure Island',
        role: 'Animation Director',
      },
      {
        anime_title: 'Grimoire of Zero',
        role: 'Character Designer',
      },
      {
        anime_title: 'Patema Inverted',
        role: 'Character Designer',
      },
      {
        anime_title: 'Fullmetal Alchemist: The Sacred Star of Milos',
        role: 'Assistant Chief Animation Director',
      },
      {
        anime_title: "Doraemon: Nobita's Great Battle of the Mermaid King",
        role: 'Key Animator',
      },
      {
        anime_title: "Doraemon the Movie: Nobita's Spaceblazer",
        role: 'Key Animator',
      },
      {
        anime_title: 'Code Geass: Lelouch of the Rebellion R2',
        role: 'Animation Director',
      },
      {
        anime_title: 'The Garden of Sinners',
        role: 'Key Animator',
      },
      {
        anime_title: 'Gurren Lagann',
        role: 'Key Animator',
      },
      {
        anime_title: 'Code Geass: Lelouch of the Rebellion',
        role: 'Assistant Animation Director',
      },
      {
        anime_title: 'Fullmetal Alchemist',
        role: 'Key Animator',
      },
      {
        anime_title: 'Naruto',
        role: 'Key Animator',
      },
    ],
  },
  {
    id: 4,
    img: `${kuriyama}`,
    name: 'Takayuki Kuriyama',
    position: 'Director',
    involvement: [
      {
        anime_title: 'Sabikui Bisco',
        role: 'Episode Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Episode Director',
      },
      {
        anime_title: 'Special 7: Special Crime Investigation Unit',
        role: 'Chief Director, Episode Director',
      },
      {
        anime_title: 'Angolmois: Record of Mongol Invasion',
        role: 'Director',
      },
      {
        anime_title: 'Promotion Video for "Match Shōjo"',
        role: 'Director, Storyboard, Episode Director, Line Producer, Production Coordinator',
      },
      {
        anime_title: 'Aldnoah.Zero',
        role: 'Episode Director',
      },
    ],
  },
  {
    id: 5,
    img: `${hondai}`,
    name: 'Takahiro Hondai',
    position: 'Compositing Director',
    involvement: [
      {
        anime_title: 'Backflip!! Movie',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Backflip!!',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Special 7: Special Crime Investigation Unit',
        role: 'Compositing Director',
      },
      {
        anime_title: 'The Great Passage',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Shōnen Hollywood - Holly Stage for 50',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Shōnen Hollywood - Holly Stage for 49',
        role: 'Compositing Director',
      },
    ],
  },
  {
    id: 6,
    img: `${hondai}`,
    name: 'Ai Asari',
    position: 'Character Designer, Animator',
    involvement: [
      {
        anime_title: 'Sabikui Bisco',
        role: 'Character Designer, Chief Animation Director, Animation Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Plop Designer, Animation Director, Assistant Chief Animation Director',
      },
      {
        anime_title: 'Fate/stay night Movie: Heaven\'s Feel - I. Presage Flower',
        role: 'Animation Director',
      },
      {
        anime_title: 'Re:ZERO -Starting Life in Another World-',
        role: 'Animation Director',
      },
      {
        anime_title: 'Terra Formars: Revenge',
        role: 'Animation Director',
      },
    ],
  },
  {
    id: 7,
    img: `${itou}`,
    name: 'Haruka Itou',
    position: 'Compositing Director',
    involvement: [
      {
        anime_title: 'Good Night World',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Backflip!! Movie',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Backflip!!',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Special 7: Special Crime Investigation Unit',
        role: 'Assistant Compositing Director',
      },
      {
        anime_title: 'Shōnen Hollywood - Holly Stage for 50',
        role: 'Assistant Compositing Director',
      },
    ],
  },
  {
    id: 8,
    img: `${nazLogo}`,
    name: 'Katsuya Kikuchi',
    position: 'Director',
    involvement: [
      {
        anime_title: 'Good Night World',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Backflip!! Movie',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Backflip!!',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Special 7: Special Crime Investigation Unit',
        role: 'Assistant Compositing Director',
      },
      {
        anime_title: 'Shōnen Hollywood - Holly Stage for 50',
        role: 'Assistant Compositing Director',
      },
    ],
  },
  {
    id: 9,
    img: `${takigawa}`,
    name: 'Kazuo Takigawa',
    position: 'Animator',
    involvement: [
      {
        anime_title: 'Bleach: Thousand-Year Blood War',
        role: 'Animation Director',
      },
      {
        anime_title: 'Sabikui Bisco',
        role: 'Animation Director',
      },
      {
        anime_title: "The Seven Deadly Sins: Dragon's Judgement",
        role: 'Action Animation Director',
      },
      {
        anime_title: 'One Punch Man Season 2',
        role: 'Storyboard',
      },
      {
        anime_title: 'The Seven Deadly Sins: Imperial Wrath of The Gods',
        role: 'Action Animation Director',
      },
      {
        anime_title: 'Angolmois: Record of Mongol Invasion',
        role: 'Episode Director, Storyboard, Animation Director, Plop Designer',
      },
      {
        anime_title: 'Pokémon the Movie: I Choose You!',
        role: 'Key Animator',
      },
      {
        anime_title: 'Re:CREATORS',
        role: 'Episode Director, Animation Director',
      },
      {
        anime_title: 'One Piece',
        role: 'Animation Director',
      },
    ],
  },
  {
    id: 10,
    img: `${yoshida}`,
    name: 'Shouta Yoshida',
    position: 'Compositing Director',
    involvement: [
      {
        anime_title: 'Gloomy the Naughty Grizzly',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Devilman Crybaby',
        role: 'Compositing Staff',
      },
    ],
  },
  {
    id: 11,
    img: `${takagi}`,
    name: 'Tasuku Takagi',
    position: 'Compositing Director',
    involvement: [
      {
        anime_title: 'Sabikui Bisco',
        role: 'Compositing Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Assistant Compositing Director',
      },
      {
        anime_title: 'My Teen Romantic Comedy SNAFU TOO!',
        role: '3D CGI',
      },
    ],
  },
  {
    id: 12,
    img: `${hisano}`,
    name: 'Toshikazu Hisano',
    position: 'Compositing Director',
    involvement: [
      {
        anime_title: 'Thermae Romae Novae',
        role: 'Compositing Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Japan Sinks: 2020 Theatrical Edition',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Japan Sinks: 2020',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Super Shiro',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Devilman Crybaby',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Angolmois: Record of Mongol Invasion',
        role: 'Compositing Director',
      },
      {
        anime_title: 'Hamatora',
        role: 'Compositing Director',
      },
    ],
  },
  {
    id: 13,
    img: `${mita}`,
    name: 'Kunihiko Mita',
    position: '3D Director',
    involvement: [
      {
        anime_title: 'Music Video "Mr. Fixer"',
        role: '3D Director',
      },
      {
        anime_title: 'Sabikui Bisco',
        role: 'CG Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'CG Director',
      },
      {
        anime_title: 'Special 7: Special Crime Investigation Unit',
        role: '3D Director',
      },
      {
        anime_title: 'The Nutcracker',
        role: '3D Director',
      },
      {
        anime_title: 'Eureka Seven: Good Night, Sleep Tight, Young Lovers',
        role: 'Monitor Works',
      },
    ],
  },
  {
    id: 14,
    img: `${nazLogo}`,
    name: 'Momoko Kawai',
    position: 'Character Designer, Animator',
    involvement: [
      {
        anime_title: 'Rhapsody',
        role: 'Character Designer',
      },
      {
        anime_title: 'Sabikui Bisco',
        role: 'Main Animator, Animation Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Main Animator, Animation Director',
      },
      {
        anime_title: 'Code Geass: Lelouch of the Re;surrection',
        role: 'Key Animator',
      },
      {
        anime_title: 'Code Geass: Lelouch of the Rebellion',
        role: 'Key Animator',
      },
      {
        anime_title: 'Mobile Suit Gundam: Iron-Blooded Orphans',
        role: 'Character Animation Director',
      },
      {
        anime_title: 'Devil Survivor 2: The Animation',
        role: 'Animation Director',
      },
      {
        anime_title: 'Persona 4: The Animation',
        role: 'Animation Director',
      },
    ],
  },
  {
    id: 15,
    img: `${nazLogo}`,
    name: 'Shingo Nakamura',
    position: 'Animator',
    involvement: [
      {
        anime_title: 'Jujutsu Kaisen',
        role: 'Key Animator',
      },
      {
        anime_title: 'My Hero Academia',
        role: 'Key Animator',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Animation Director',
      },
      {
        anime_title: 'Special 7: Special Crime Investigation Unit',
        role: 'Animation Director',
      },
      {
        anime_title: 'Angolmois: Record of Mongol Invasion',
        role: 'Animation Director',
      },
      {
        anime_title: 'Re:CREATORS',
        role: 'Animation Director',
      },
      {
        anime_title: 'Food Wars!: Shokugeki no Soma',
        role: 'Animation Director',
      },
    ],
  },
  {
    id: 16,
    img: `${nazLogo}`,
    name: 'Rena Okuyama',
    position: 'Character Designer, Animator',
    involvement: [
      {
        anime_title: 'Good Night World',
        role: 'Character Designer',
      },
      {
        anime_title: 'The Royal Tutor Movie - Unknown',
        role: 'Character Designer',
      },
      {
        anime_title: 'The Royal Tutor',
        role: 'Character Designer',
      },
      {
        anime_title: 'Sengoku Night Blood',
        role: 'Character Designer',
      },
      {
        anime_title: 'Idol Memories',
        role: 'Character Designer',
      },
    ],
  },
  {
    id: 17,
    img: `${nazLogo}`,
    name: 'Norie Igawa',
    position: 'Character Designer/Animator',
    involvement: [
      {
        anime_title: 'The IdolM@ster Cinderella Girls: U149',
        role: 'Animation Character Designer, Chief Animation Director, Animation Director for Live Part, Animation Director, Assistant Animation Director',
      },
      {
        anime_title: 'The IdolM@ster Cinderella Girls 10th Anniversary Celebration Animation "ETERNITY MEMORIES"',
        role: 'Character Designer, Chief Animation Director, Animation Director',
      },
      {
        anime_title: 'Sabikui Bisco',
        role: 'Chief Animation Director',
      },
      {
        anime_title: 'Music Video "Mr. Fixer"',
        role: 'Character Designer, Animation Director',
      },
      {
        anime_title: 'Pokémon: Twilight Wings',
        role: 'Character Design Cooperation, Animation Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Animation Director',
      },
      {
        anime_title: 'Infinite Dendrogram',
        role: 'Subcharacter Designer',
      },
      {
        anime_title: 'Goblin Slayer',
        role: 'Animation Director',
      },
      {
        anime_title: 'Grimoire of Zero',
        role: 'Animation Director',
      },
    ],
  },
  {
    id: 18,
    img: `${nazLogo}`,
    name: 'Haruka Sanefuji',
    position: 'Animator',
    involvement: [
      {
        anime_title: 'Good Night World',
        role: 'Chief Animation Director',
      },
      {
        anime_title: 'Sabikui Bisco',
        role: 'Animation Director',
      },
      {
        anime_title: 'Shaman King',
        role: 'Subcharacter Designer',
      },
      {
        anime_title: 'The Royal Tutor Movie - Unknown',
        role: 'Assistant Chief Animation Director',
      },
      {
        anime_title: 'The Royal Tutor',
        role: 'Animation Director',
      },
      {
        anime_title: 'Uta no Prince Sama: Legend Star',
        role: 'Animation Director',
      },
      {
        anime_title: 'Mr. Osomatsu',
        role: 'Animation Director',
      },
      {
        anime_title: 'Fairy Tail',
        role: 'Animation Director',
      },
    ],
  },
  {
    id: 19,
    img: `${nazLogo}`,
    name: 'Yukie Kaneko',
    position: 'Animation Director',
    involvement: [
      {
        anime_title: 'Good Night World',
        role: 'Animation Director',
      },
      {
        anime_title: 'Sabikui Bisco',
        role: 'Animation Director',
      },
      {
        anime_title: 'Yowamushi Pedal: Limit Break',
        role: 'In-Between Animation Inspector',
      },
      {
        anime_title: 'SPY×FAMILY',
        role: 'In-Between Animation Inspector',
      },
      {
        anime_title: 'Jujutsu Kaisen 0',
        role: 'In-Between Animator',
      },
      {
        anime_title: 'Attack on Titan',
        role: 'In-Between Animation Assistant Inspector',
      },
      {
        anime_title: 'Pokémon the Movie: The Power of Us',
        role: 'In-Between Animator',
      },
      {
        anime_title: "Howl's Moving Castle",
        role: 'In-Between Animator',
      },
    ],
  },
  {
    id: 20,
    img: `${nazLogo}`,
    name: 'Hitomi Takechi',
    position: 'Animator',
    involvement: [
      {
        anime_title: 'Sabikui Bisco',
        role: 'Main Animator, Animation Director',
      },
      {
        anime_title: 'ID:INVADED',
        role: 'Key Animator',
      },
      {
        anime_title: 'My Hero Academia Season 4',
        role: 'Animation Director',
      },
      {
        anime_title: 'Pokémon the Movie: The Power of Us',
        role: 'Key Animator',
      },
      {
        anime_title: 'My Hero Academia Season 3',
        role: 'Key Animation Director Cooperation',
      },
      {
        anime_title: 'Planetarian: Storyteller of the Stars',
        role: 'Character Designer',
      },
      {
        anime_title: 'Planetarian: The Reverie of a Little Planet',
        role: 'Character Designer',
      },
      {
        anime_title: 'Hyperdimension Neptunia: The Animation',
        role: 'Character Designer',
      },
      {
        anime_title: 'Code Geass: Akito the Exiled',
        role: 'Assistant Animation Director',
      },
      {
        anime_title: 'Fullmetal Alchemist',
        role: 'Animation Director, Assistant Animation Director',
      },
      {
        anime_title: 'Code Geass: Lelouch of the Rebellion R2',
        role: 'Assistant Animation Director',
      },
      {
        anime_title: 'Sergeant Keroro',
        role: 'Animation Director, Key Animation Supervisor, Key Animation Cooperation',
      },
    ],
  },
];

export default staffConfig;
