import { Locale } from '../types';
import { selectCurrentLocale } from '../store/auth.slice';
import { useAppSelector } from './store';

/**
 * Returns the current logged in user
 *
 * @returns {string}
 */
export const useLocale = (): Locale => useAppSelector(selectCurrentLocale);

export default useLocale;
