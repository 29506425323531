import React from 'react';
import { Alert } from 'react-bootstrap';

/**
 * Displays an error message in an alert component when isError is true and error is not null
 * or if error object contains a message property
 *
 * @param {*} props
 * @param {boolean} props.isError - error state
 * @param {string} props.error - error message
 * @returns {*}
 */
const RemoteErrorAlert = (props: any) => {
  const { error } = props;
  const message = error.message ? error.message : error.status;
  return (
    <><Alert variant='warning' hidden={!props.isError}>{message}</Alert></>
  );
};

export default RemoteErrorAlert;
