/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

/**
 * <p>ResponseMedadata</p>
 * @interface
 * @property {any} body
 * @property {Map<string, string>} headers
 * @name ResponseMetadata
 * @type {Interface}
 */
export interface ResponseMetadata {
  body: any;
  headers: Map<string, string>;
}

/**
 * <p>Request metadata contains an optional prefix which can be used to apply different contexts to entire request path</p>
 * @interface
 * @property {string} prefix
 * @property {any} body
 * @name RequestMetadata
 * @type {Interface}
 * */
export interface RequestMetadata {
  prefix?: string;
  body?: any;
}

/**
 * <p>Request to list media items</p>
 * @interface
 * @property {string} prefix
 * @name ListMediaRequest
 * @type {Interface}
 * */
export interface ListMediaRequest extends RequestMetadata {}

/**
 * <p>Response to list media items</p>
 * @interface
 * @property {MediaItem[]} body
 * @name ListMediaResponse
 * @type {Interface}
 * */
export interface ListMediaResponse extends ResponseMetadata{}

/**
 * <p>Request to get a media item</p>
 * @interface
 * @property {number} id
 * @name GetMediaRequest
 * @type {Interface}
 * */
export interface GetMediaRequest extends RequestMetadata {
  id: number;
}

/**
 * <p>Response to list media items</p>
 * @interface
 * @property {MediaItem} body
 * @name GetMediaResponse
 * @type {Interface}
 * */
export interface GetMediaResponse extends ResponseMetadata {}

/**
 * <p>Request to get a media item</p>
 * @interface
 * @property {number} id
 * @name GetIndivdualMediaRequest
 * @type {Interface}
 * */
export interface GetIndividualMediaRequest extends RequestMetadata {
  id: number;
}

/**
 * <p>Response to list media items</p>
 * @interface
 * @property {MediaItem} body
 * @name GetInvidualMediaResponse
 * @type {Interface}
 * */
export interface GetIndividualMediaResponse extends ResponseMetadata {}

/**
 * <p>Request to get a media item</p>
 * @interface
 * @property {number} id
 * @name GetIndivdualMediaRequest
 * @type {Interface}
 * */
export interface GetIndividualMediaByTenantRequest extends RequestMetadata {
  id: number;
}

/**
 * <p>Response to list media items</p>
 * @interface
 * @property {MediaItem} body
 * @name GetInvidualMediaResponse
 * @type {Interface}
 * */
export interface GetIndividualMediaByTenantResponse extends ResponseMetadata {}

/**
 * <p>Request to list media items</p>
 * @interface
 * @property {string} locale
 * @name ListTranslationRequest
 * @type {Interface}
 * */
export interface ListTranslationRequest extends RequestMetadata {}

/**
 * <p>Response to list media items</p>
 * @interface
 * @property {MediaItem[]} body
 * @name ListTranslationResponse
 * @type {Interface}
 * */
export interface ListTranslationResponse extends ResponseMetadata{}

/**
 * <p>Request to get a media item</p>
 * @interface
 * @property {string} locale
 * @property {number} id
 * @name GetTranslationRequest
 * @type {Interface}
 * */
export interface GetTranslationRequest extends RequestMetadata {
  id: number;
  locale: string;
}

/**
 * <p>Response to list media items</p>
 * @interface
 * @property {MediaItem} body
 * @name GetTranslationResponse
 * @type {Interface}
 * */
export interface GetTranslationResponse extends ResponseMetadata {}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URI,
  prepareHeaders: (headers) => headers,
});

/**
 * <p>Base query with authentication dispatch</p>
 * @param {string | FetchArgs} args
 * @param {any} api
 * @param {any} extraOptions
 * @returns {Promise<unknown>}
 * @throws {FetchBaseQueryError}
 * @returns {Promise<unknown>}
 * @throws {FetchBaseQueryError}
 * @returns {Promise<unknown>}
 * @throws {FetchBaseQueryError}
 */
const baseQueryWithAuthDispatch: BaseQueryFn<string | FetchArgs,
  unknown,
  FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
  ) => {
    const result = await baseQuery(
      args,
      api,
      extraOptions,
    );
    return result;
  };

/**
 * <p>Response handler will position the resopnse payload into the body property and response headers into the header property</p>
 * @param {any} response
 * @returns {Promise<ResponseMetadata>}
  */
const responseHandler = async (response: any) => {
  const headers: Map<string, string> = new Map();
  response.headers.forEach((value: any, name: any) => {
    headers.set(name, value);
  });

  return {
    body: await response.json(),
    headers,
  };
};

/**
 * <p>Engage API</p>
 * @returns {any}
 */
export const engageApi = createApi({
  baseQuery: baseQueryWithAuthDispatch,
  endpoints: (builder) => ({
    getMedia: builder.query<GetMediaResponse,
      GetMediaRequest>({
        query: (request) => ({
          url: `media/${request.id}`,
          method: 'GET',
          mode: 'cors',
          responseHandler,
        }),
      }),
    getIndividualMediaByTenant: builder.query<GetIndividualMediaByTenantResponse,
      GetIndividualMediaByTenantRequest>({
        query: (request) => ({
          url: `media/${request.id}/tenants/naz`,
          method: 'GET',
          mode: 'cors',
          responseHandler,
        }),
      }),
    getIndividualMedia: builder.query<GetIndividualMediaResponse,
      GetIndividualMediaRequest>({
        query: (request) => ({
          url: `media/${request.id}`,
          method: 'GET',
          mode: 'cors',
          responseHandler,
        }),
      }),
    listMediaByTenant: builder.query<ListMediaResponse,
      ListMediaRequest>({
        query: () => ({
          url: 'media/tenants/naz',
          method: 'GET',
          mode: 'cors',
          responseHandler,
        }),
      }),
    getTranslations: builder.query<GetTranslationResponse,
      GetTranslationRequest>({
        query: (request) => ({
          url: `media/${request.id}/translations/${request.locale}`,
          method: 'GET',
          mode: 'cors',
          responseHandler,
        }),
      }),
    listTranslations: builder.query<ListTranslationResponse,
      ListTranslationRequest>({
        query: () => ({
          url: 'media/translations/ja/tenants/naz',
          method: 'GET',
          mode: 'cors',
          responseHandler,
        }),
      }),
  }),
});

export const {
  useGetMediaQuery, useListMediaByTenantQuery, useGetIndividualMediaQuery,
  useGetTranslationsQuery, useListTranslationsQuery,
} = engageApi;
