import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/useLocale';
import i18n from '../i18n';
import style from './Contact.module.css';

const Contact = () => {
  const { t } = useTranslation();
  const locale = useLocale();

  useEffect(() => {
    i18n.changeLanguage(locale.languageCode);
  }, [locale.languageCode]);
  return (
    <div className={style.sectionContainer1} id="contact">
      <div>
        <h1 className={style.secondaryTitleContact}>{t('title_contactUs')}</h1>
      </div>
      <div className={style.info}>
        <Row>
          <Col xs={6} sm={2}>
            {t('contact_companyName')}
          </Col>
          <Col xs={6} sm={2}>
            {t('contact_companyNameInfo')}
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={2}>
            {t('contact_Establishment')}
          </Col>
          <Col xs={6} sm={2}>
            {t('contact_EstablishmentInfo')}
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={2}>
            {t('contact_Representative')}
          </Col>
          <Col xs={6} sm={2}>
            {t('contact_RepresentativeInfo')}
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={2}>
            {t('contact_Email')}
          </Col>
          <Col xs={6} sm={2}>
            <a
              href="mailto:info@naz.co.jp"
              style={{ textDecoration: 'underline', color: 'white' }}
            >
              info@naz.co.jp
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
