import React from 'react';

/**
 * MediaCard component displays a single media item in a card
 * @param {MediaItem} props.item - the media item to display
 * @param props item: MediaItem
 * @returns {*}
 */
const MediaCard = (props: any) => (
  <div>
    <img
      src={props.item.cover_image_url || props.item.thumbnail_url}
      alt={props.item.title}
      className="img-fluid"
    />
    <p>{props.item.copyright}</p>
  </div>
);

export default MediaCard;
