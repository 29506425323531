import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';

/**
 * Returns AppDispatch
 *
 * @returns {*}
 */
export const useAppDispatch = (): any => useDispatch<AppDispatch>();

/**
 * Returns the appSelector
 *
 * @type {TypedUseSelectorHook<RootState>}
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
