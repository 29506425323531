import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './custom.scss';
import './styles.css';
import { Route, Routes } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './views/ErrorFallback';
import NavigationLayout from './layouts/NavigationLayout';
import Home from './views/Home';
import About from './views/About';
import Gallery from './views/Gallery';
import Culture from './views/Culture';
import Footer from './components/Footer';
import Contact from './views/Contact';
import ListMedia from './views/media/ListMedia';
import Root from './views/Root';

/**
 * The main application component. It uses the `ErrorBoundary` to catch and display errors. The `Routes` component
 * defines the application routes. The `Route` components define the individual routes. The `Navigate` component
 * activates a navigation bar. The `Root` component redirects the user to the default language and country.
 * @param props
 * @param props.initalLocale - the locale object
 * @returns {*}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Routes>
      <Route path="/" element={<Root />} />
      <Route element={<NavigationLayout />}>
        <Route path=":lang" element={<Home />} />
        <Route path=":lang/about" element={<About />} />
        <Route path=":lang/works" element={<ListMedia />} />
        <Route path=":lang/gallery" element={<Gallery />} />
        <Route path=":lang/culture" element={<Culture />} />
      </Route>
    </Routes>
    <div id="contact">
      <Contact />
    </div>
    <Footer />
  </ErrorBoundary>
);

export default App;
