import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import {
  useListMediaByTenantQuery,
  useListTranslationsQuery,
} from '../../api/engage.api';
import RemoteErrorAlert from '../../components/RemoteErrorAlert';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useLocale } from '../../hooks/useLocale';
import Works from '../Works';

/**
 * Home view component displays a grid of media items
 * @returns {*}
 */
const ListMedia = () => {
  const location = useLocation();
  const locale = useLocale();
  const basePath = `/${locale.languageCode}`;
  /**
   * Get the media items from the API
   */
  const query = locale.languageCode === process.env.REACT_APP_DEFAULT_LANGUAGE
    ? useListMediaByTenantQuery({})
    : useListTranslationsQuery({});

  const {
    data, isLoading, isSuccess, isError, error,
  } = query;
  /**
   * Render the media item grid, a loading spinner, or an error alert
   */
  let content;
  let alert;
  const loading = <LoadingSpinner isLoading={isLoading} />;
  if (isSuccess) {
    if (location.pathname === `${basePath}/works`) {
      content = <Works data={data.body} />;
    }
  } else if (isError) {
    alert = <RemoteErrorAlert isError={isError} error={error} />;
  }

  return (
    <Container fluid className="content">
      {loading}
      {alert}
      <Container>{content}</Container>
    </Container>
  );
};

export default ListMedia;
