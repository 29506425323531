import React, { useRef, useEffect } from 'react';
import shaka from 'shaka-player';
import { useLocation } from 'react-router';
import { useLocale } from '../hooks/useLocale';
import styles from './ShakaPlayer.module.css';

const ShakaPlayer = (props: any) => {
  const videoComponent = useRef(null);
  const location = useLocation();
  const { languageCode } = useLocale();

  const onError = (error: any) => {
    console.error('Error code', error.code, 'object', error);
  };

  const onErrorEvent = (event: any) => {
    onError(event.detail);
  };

  useEffect(() => {
    const video = videoComponent.current;
    const player = new shaka.Player(video);

    player.addEventListener('error', onErrorEvent);

    player.load(props.video).catch(onError);

    return () => {
      player.removeEventListener('error', onErrorEvent);
    };
  }, []);

  return (
    <video
      className={styles.responsiveVideo}
      ref={videoComponent}
      controls={location.pathname === `/${languageCode}/works`}
      autoPlay={location.pathname !== `/${languageCode}/works`}
      loop
    />
  );
};

export default ShakaPlayer;
