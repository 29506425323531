import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import NavigationLinks from '../components/NavigationLinks';
import Brand from '../components/Brand';
import LanguageToggle from '../components/LanguageToggle';
import MobileNavigation from '../components/MobileNavigation';
import styles from './TopNav.module.css';
import hamburger from '../assets/images/hamburger.svg';

/**
 * Main top navigation toolbar
 *
 * @returns {*}
 */
const TopNav = () => {
  const [isMobileView, setIsMobileView] = React.useState(false);
  const toggleMobileView = () => setIsMobileView(!isMobileView);

  return (
    <Container>
      <Navbar fixed="top" className={styles.topNav}>
        <div>
          <Brand />
        </div>
        <div className={styles.rightNav}>
          <div
            className={`${
              isMobileView ? styles.active : styles.mobileNavButton
            }`}
          >
            <LanguageToggle />
            <div
              className={styles.hamburgerContainer}
              onClick={toggleMobileView}
              role="button"
              aria-expanded={isMobileView}
            >
              <img src={hamburger} alt="mobile menu" width={32} height={32} />
            </div>
          </div>
          <div
            className={`${styles.mobileNavContainer} ${
              isMobileView ? styles.mobileNavVisible : styles.mobileNavHidden
            }`}
          >
            {isMobileView && <MobileNavigation />}
          </div>
          <div className={styles.desktopNav}>
            <NavigationLinks />
            <LanguageToggle />
          </div>
        </div>
      </Navbar>
    </Container>
  );
};

export default TopNav;
