/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useLocale } from '../hooks/useLocale';
import { useAppDispatch } from '../hooks/store';
import { setLanguageDidChange } from '../store/auth.slice';
import styles from './LanguageToggle.module.css';
import i18n from '../i18n';

/**
 * Navigation component that displays the appropriate navigation based on the user's authentication status.
 * @returns {*}
 * */
export const LanguageNav = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let selectedLanguage = t(`lc_${locale.languageCode}`);

  /**
   * Changes the selected country and dispaches the change to the Redux store
   * @param newCountry
   */
  const handleLanguageChanged = async (newLanguageCode: string) => {
    const newPath = location.pathname.replace(
      /^\/[a-z]+\//,
      `/${newLanguageCode}/`,
    );
    dispatch(setLanguageDidChange({ newLanguageCode }));
    navigate(newPath, { replace: true });
  };

  /**
   * Callback handling the i18 language change changes the selected language and dispaches the change to the Redux store
   * @param newLanguage
   */
  const changeLanguage = async (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    selectedLanguage = t(`lc_${newLanguage}`);
    handleLanguageChanged(newLanguage);
  };

  return (
    <Nav className={styles.test}>
      <Nav.Item>
        <NavDropdown
          title={selectedLanguage}
          id={styles.navDropdown}
          className={styles.navDropdownMenu}
          onSelect={(eventKey: string | null) => eventKey && changeLanguage(eventKey)
          }
        >
          <NavDropdown.Item
            eventKey="en"
            className={styles.dropdownDiv}
            style={{ backgroundColor: 'black', color: 'white' }}
          >
            {t('lc_en')}
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="ja"
            className={styles.dropdownDiv}
            style={{ backgroundColor: 'black', color: 'white' }}
          >
            {t('lc_ja')}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
    </Nav>
  );
};

export default LanguageNav;
