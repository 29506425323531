import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/useLocale';
import styles from './NavigationLinks.module.css';
import i18n from '../i18n';

const NavigationLinks = () => {
  const location = useLocation();
  const locale = useLocale();
  const basePath = `/${locale.languageCode}`;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale.languageCode);
  }, [locale.languageCode]);
  return (
    <Nav>
      <div className={styles.container}>
        <Nav.Link
          href={`${basePath}/about`}
          className={
            location.pathname === `${basePath}/about`
              ? `${styles.activeNavLink}`
              : `${styles.navLink}`
          }
        >
          {t('nav_about')}
        </Nav.Link>
      </div>
      <div className={styles.container}>
        <Nav.Link
          href={`${basePath}/works`}
          className={
            location.pathname === `${basePath}/works`
              ? `${styles.activeNavLink}`
              : `${styles.navLink}`
          }
        >
          {t('nav_works')}
        </Nav.Link>
      </div>
      <div className={styles.container}>
        <Nav.Link
          href={`${basePath}/gallery`}
          className={
            location.pathname === `${basePath}/gallery`
              ? `${styles.activeNavLink}`
              : `${styles.navLink}`
          }
        >
          {t('nav_gallery')}
        </Nav.Link>
      </div>
      <div className={styles.container}>
        <Nav.Link
          href={`${basePath}/culture`}
          className={
            location.pathname === `${basePath}/culture`
              ? `${styles.activeNavLink}`
              : `${styles.navLink}`
          }
        >
          {t('nav_culture')}
        </Nav.Link>
      </div>
      <div className={styles.container}>
        <Nav.Link
          href="#contact"
          className={
            location.hash === '#contact'
              ? `${styles.activeNavLink}`
              : `${styles.navLink}`
          }
        >
          {t('nav_contact')}
        </Nav.Link>
      </div>
    </Nav>
  );
};

export default NavigationLinks;
