/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/**
 * Root component to redirect to the default locale of the application
 *
 * @param props
 * @param props.initialLocale - the locale object
 * @returns {*}
 */

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocale } from '../hooks/useLocale';
import { Locale } from '../types';
// import { setLocaleDidChange, setUserTrackingId } from '../store/auth.slice';
// import { useAppDispatch } from '../hooks/store';
import LoadingSpinner from '../components/LoadingSpinner';

// const fetchLocaleData = async (locale: Locale) => {
//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_TELEMETRY_BASE_URI}/bootstrap`,
//       { method: 'GET' }
//     );
//     const updatedLocale = { ...locale };
//     await response.headers.forEach((value, name) => {
//       name === 'x-city' ? (updatedLocale.city = value) : '';
//       name === 'x-country-code' ? (updatedLocale.countryCode = value) : '';
//       name === 'x-country-region' ? (updatedLocale.countryRegion = value) : '';
//       name === 'x-latitude' ? (updatedLocale.latitude = Number(value)) : '';
//       name === 'x-longitude' ? (updatedLocale.longitude = Number(value)) : '';
//       name === 'x-postal-code'
//         ? (updatedLocale.postalCode = Number(value))
//         : '';
//       name === 'x-preferred-language'
//         ? (updatedLocale.languageCode = value)
//         : '';
//     });

//     const { t_id } = await response.json();

//     return { updatedLocale, t_id };
//   } catch (error) {
//     console.error('Failed to fetch locale data:', error);
//     throw new Error('Failed to fetch locale data');
//   }
// };

const Root = () => {
  // const dispatch = useAppDispatch();
  const locale: Locale = useLocale();

  // useEffect(() => {
  //   const updateLocaleData = async () => {
  //     try {
  //       const { updatedLocale, t_id } = await fetchLocaleData(locale);

  //       let newLocale = updatedLocale;

  //       if (newLocale.languageCode.length > 2) {
  //         // to cover for cases where langauge looks like: en-GB or en;q-0.7
  //         newLocale = {
  //           ...newLocale,
  //           languageCode: newLocale.languageCode.slice(0, 2),
  //         };
  //       }

  //       if (locale.countryCode !== null) {
  //         newLocale = {
  //           ...updatedLocale,
  //           languageCode: locale.languageCode,
  //           countryCode: locale.countryCode,
  //         };
  //       }

  //       // Dispatch updates to Redux
  //       dispatch(setLocaleDidChange({ locale: newLocale }));
  //       dispatch(setUserTrackingId({ t_id }));
  //     } catch (error) {
  //       console.error('Failed to fetch locale data:', error);
  //     }
  //   };

  //   updateLocaleData();
  // }, []);

  return (
    <>
      {locale ? (
        <Navigate to={`${locale.languageCode}/`} replace />
      ) : (
        <LoadingSpinner isLoading={true} />
      )}
    </>
  );
};

export default Root;
