import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/useLocale';
import style from './About.module.css';
import about1 from '../assets/images/about/img-about-1.jpg';
import about2 from '../assets/images/about/img-about-2.jpg';
import about3 from '../assets/images/about/img-about-3.jpg';
import about4 from '../assets/images/about/img-about-4.jpg';
import about5 from '../assets/images/about/img-about-5.jpg';
import about6 from '../assets/images/about/img-about-6.jpg';
import trophy from '../assets/images/about/trophy.svg';
import AccordionComponent from '../components/AccordionComponent';
import i18n from '../i18n';

const awards = [
  {
    img: `${trophy}`,
    title: '2019-2020 NewType Anime Award',
    title2: 'Best Sound No. 1',
    title3: 'ID:INVADED',
  },
  {
    img: `${trophy}`,
    title: '2019-2020 NewType Anime Award',
    title2: 'Best Studio No. 2',
    title3: 'NAZ',
  },
  {
    img: `${trophy}`,
    title: '2019-2020 NewType Anime Award',
    title2: 'Best Animation No. 2',
    title3: ' ',
  },
];

const AboutParagraphSection = (props: any) => (
  <div className={style.sectionContainer2}>
    <div>
      <img src={about1} className={style.responsiveImage} />
      <p className={style.marginStyling}>{props.t('aboutPagePara1')}</p>
    </div>
    <div className={`${style.flexContainer} ${style.marginStyling}`}>
      <img
        className={`${style.paragraphStyling} ${style.responsiveImage}`}
        src={about2}
      />
      <p>{props.t('aboutPagePara2')}</p>
    </div>
    <div className={`${style.flexContainer} ${style.marginStyling}`}>
      <p className={style.paragraphStyling}>{props.t('aboutPagePara3')}</p>
      <img src={about3} className={style.responsiveImage} />
    </div>
    <div>
      <img
        className={`${style.responsiveImage} ${style.marginStyling}`}
        src={about4}
      />
      <p className={style.marginStyling}>{props.t('aboutPagePara4')}</p>
    </div>
    <div className={`${style.flexContainer} ${style.marginStyling}`}>
      <p className={style.paragraphStyling}>{props.t('aboutPagePara5')}</p>
      <img src={about5} className={style.responsiveImage} />
    </div>
    <div>
      <p className={style.marginStyling}>{props.t('aboutPagePara6')}</p>
      <img
        className={`${style.responsiveImage} ${style.marginStyling}`}
        src={about6}
      />
      <p className={style.marginStyling}>{props.t('aboutPagePara7')}</p>
    </div>
  </div>
);

const AboutPrizesSection = (props: any) => {
  const { t } = useTranslation();
  const awardsMap = awards.map((award) => (
    <div className={style.awardItem} key={award.title2}>
      <img src={award.img} width={120} height={120} />
      <p>{t(`prizes.${award.title}`)}</p>
      <p>{t(`prizes.${award.title2}`)}</p>
      <p>{award.title3}</p>
    </div>
  ));
  return (
    <div className={style.sectionContainer1}>
      <div>
        <h2 className={style.secondaryTitleAwards}>
          {props.t('title_awards')}
        </h2>
      </div>
      <div className={style.prizesflexContainer}>{awardsMap}</div>
    </div>
  );
};

const AboutStaffSection = (props: any) => (
  <div className={style.sectionContainer1}>
    <div>
      <h2 className={style.secondaryTitleStaff}>{props.t('title_staff')}</h2>
    </div>
    <AccordionComponent />
  </div>
);

const About = () => {
  const { t } = useTranslation();
  const locale = useLocale();

  useEffect(() => {
    i18n.changeLanguage(locale.languageCode);
  }, [locale.languageCode]);
  return (
    <div>
      <div className={style.sectionContainer1}>
        <h1 className={style.title}>{t('title_aboutNAZ')}</h1>
      </div>
      <AboutParagraphSection t={t} />
      <AboutPrizesSection t={t} />
      <AboutStaffSection t={t} />
    </div>
  );
};

export default About;
