import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/useLocale';
import WorksModalComponent from '../components/WorksModalComponent';
import styles from './Works.module.css';

const Works = (props: any) => {
  const { languageCode } = useLocale();
  const apiBaseUri = process.env.REACT_APP_API_BASE_URI;
  const [showModal, setShowModal] = React.useState(false);
  const [selectedWork, setSelectedWork] = React.useState(null);
  const [worksData, setWorksData] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const handleCredits = async () => {
      if (languageCode !== 'en') {
        const fetchPromises = props.data.map(async (item: any) => {
          const response = await fetch(
            `${apiBaseUri}/media/${item.media_item_id}/tenants/naz`,
          );
          return response.json();
        });

        try {
          const englishWorksDetails = await Promise.all(fetchPromises);
          const mergedData = props.data.map((item: any, index: any) => ({
            ...item,
            copyright: englishWorksDetails[index].copyright,
            release_date: englishWorksDetails[index].release_date,
            cover_image_url: englishWorksDetails[index].cover_image_url,
            trailer_video_url: englishWorksDetails[index].trailer_video_url,
            official_twitter_url:
              englishWorksDetails[index].official_twitter_url,
            official_url: englishWorksDetails[index].official_url,
          }));
          setWorksData(mergedData);
        } catch (error) {
          console.error('Failed to fetch or parse responses:', error);
        }
      } else {
        setWorksData(props.data);
      }
    };

    handleCredits();
  }, [props.data, languageCode, apiBaseUri]);

  const handleShow = (work: any) => {
    setShowModal(true);
    setSelectedWork(work);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedWork(null);
  };

  // Group data by year
  const groupedByYear = worksData.reduce((acc: any, item: any) => {
    const year = new Date(item.release_date).getFullYear();
    if (!acc[year]) acc[year] = [];
    acc[year].push(item);
    return acc;
  }, {});

  return (
    <>
      <div className={styles.verticalCenter}>
        <div>
          <h1 className={styles.title}>{t('nav_works')}</h1>
          {Object.entries(groupedByYear)
            .sort((a, b) => Number(b[0]) - Number(a[0]))
            .map(([year, items]: any) => (
              <div className={styles.containerStyling} key={year}>
                <h2 className={styles.year}>{year}</h2>
                <Row>
                  {items.map((item: any) => (
                    <Col key={item.id} xs={12} sm={4}>
                      <div className={styles.clicked}>
                        <img
                          src={item.cover_image_url || item.thumbnail_url}
                          alt={item.title}
                          className="img-fluid"
                          onClick={() => handleShow(item)}
                        />
                        <p className={styles.copyrightText}>{item.copyright}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
        </div>
      </div>
      {showModal && selectedWork && (
        <WorksModalComponent handleClose={handleClose} work={selectedWork} />
      )}
    </>
  );
};

export default Works;
