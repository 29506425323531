import * as React from 'react';
import { Alert, Container } from 'react-bootstrap';
import logo from '../assets/images/logo-dk.png';

/**
 * ErrorFallback component displays a fallback error message
 * @returns {*}
 */
const ErrorFallback = () => (
  <Container fluid className="verticalCenter">
    <div className="center">
      <img height={75} width={75} alt="logo" src={logo} />
      <Alert show variant="warning">
        Somethig went wrong. Please try again later or{' '}
        <Alert.Link href="#">contact support</Alert.Link>.
      </Alert>
    </div>
  </Container>
);

export default ErrorFallback;
