/* eslint-disable no-unused-expressions */
/* eslint-disable jest/require-hook */
/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { mediaSlice } from '../store/media.slice';

const sendBeaconData = async (beaconName: any, location: any, id: any) => {
  const body = JSON.stringify({
    name: beaconName,
    location,
    properties: {
      content_id: id,
    },
  });

  await fetch(`${process.env.REACT_APP_TELEMETRY_BASE_URI}/beacon`, {
    method: 'PUT',
    body,
  });
};

const handleMediaAction = async (action: any, listenerApi: any, actionType: string) => {
  listenerApi.cancelActiveListeners();
  const { location, id } = action.payload.current;

  await sendBeaconData(actionType, location, id);
  console.log(`User action "${actionType}" for item ${id} at location ${location}`);
};

export const mediaListeners: any[] = [
  {
    // when the user SELECTS one particular content
    actionCreator: mediaSlice.actions.setUserDidSelectItem,
    effect: async (action: any, listenerApi: any) => {
      await handleMediaAction(action, listenerApi, 'userDidSelectItem');
    },
  },
  {
    // when the user STARTS TO CREATE one particular content
    actionCreator: mediaSlice.actions.setUserDidStartCreateItem,
    effect: async (action: any, listenerApi: any) => {
      await handleMediaAction(action, listenerApi, 'userDidStartCreateItem');
    },
  },
  {
    // when the user COMPLETES CREATION OF one particular content
    actionCreator: mediaSlice.actions.setUserDidCompleteCreateItem,
    effect: async (action: any, listenerApi: any) => {
      await handleMediaAction(action, listenerApi, 'userDidCompleteCreateItem');
    },
  },
];
