import React from 'react';
import {
  Container, Row, Col, Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './Culture.module.css';
import rawCultureMapping from '../assets/text/cultureMapping.json';

// Define a type that includes an index signature
interface CultureMapping {
  [key: string]: string;
}

const cultureMapping: CultureMapping = rawCultureMapping as CultureMapping;

const Culture = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState('');
  const { t } = useTranslation();

  const handleImageClick = (imageUrl: string): void => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  // Function to close the modal
  const handleClose = (): void => {
    setShowModal(false);
  };

  const displayCulture = Object.keys(cultureMapping).map((key: string) => (
    <Col key={key} xs={12} md={4}>
      <img
        className={style.imgStyle}
        src={`${process.env.REACT_APP_STATIC_BASE_URI}/${cultureMapping[key]}`}
        alt={key}
        onClick={() => handleImageClick(
          `${process.env.REACT_APP_STATIC_BASE_URI}/${cultureMapping[key]}`,
        )
        }
      />
    </Col>
  ));

  return (
    <Container className={style.verticalCenter}>
      <h1 className={style.title}>{t('nav_culture')}</h1>
      <Row>{displayCulture}</Row>
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={currentImage} className={style.largeImg} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Culture;
