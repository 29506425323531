import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '.';
import { MediaItem } from '../types';

/**
 * State interface managuned under the 'media' names space segmented by locale. Location "switching". When a user changes language or location,
 * the locale key will change and trigger a cache invalidation.
 */
export interface MediaState {
  items: MediaItem[]
  current: {
    id: number | null,
    location: string,
  } | null;
  error: Error | null;
}

/**
 * Initializates the state to our default media items and clears user selections
 */
const initialState: MediaState = { items: [], current: null, error: null };

/**
 * Redux slice under the 'auth' namespace handles user actions during the auth workflow
 * and state management
 *
 * @type {*}
 */
export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    /**
     * Called after the user selects a media item
     *
     * @param state current state
     * @param action with payload containing the selected media item
     * @returns updated state
     */
    setUserDidSelectItem: (state, { payload: { current } }) => ({
      ...state,
      current,
      error: null,
    }),

    /**
     * Called when the user starts creating a new media item
     *
     * @param state current state
     * @param action with payload containing the new media item being created
     * @returns updated state
     * */
    setUserDidStartCreateItem: (state, { payload: { current: { location } } }) => ({
      ...state,
      current: {
        id: null,
        location,
      },
      error: null,
    }),

    /**
     * Called after the user successfully creates a new media item and the user's current selection is updated.
     *
     * @param state current state
     * @param action with payload containing the new media item created
     * @returns updated state
     */
    setUserDidStartUpdateItem: (state, { payload: { current } }) => ({
      ...state,
      current,
      error: null,
    }),

    /**
     * Called after the user successfully creates a new media item and the user's current selection is updated.
     *
     * @param state current state
     * @param action with payload containing the new media item created
     * @returns updated state
     */
    setUserDidCompleteUpdateItem: (state, { payload: { current } }) => ({
      ...state,
      current,
      error: null,
    }),

    /**
     * Called after the user successfully creates a new media item and the user's current selection is updated.
     *
     * @param state current state
     * @param action with payload containing the new media item created
     * @returns updated state
     * */
    setUserDidCompleteCreateItem: (state, { payload: { current } }) => ({
      ...state,
      current,
      error: null,
    }),
  },
});

/**
 * Export supported actions
 */
export const {
  setUserDidSelectItem, setUserDidStartCreateItem, setUserDidCompleteCreateItem, setUserDidCompleteUpdateItem, setUserDidStartUpdateItem,
} = mediaSlice.actions;

export default mediaSlice.reducer;

/**
 * Select items the user is authorized to access
 *
 * @param state current state
 * @returns available items
 */
export const selecItems = (state: RootState) => state.media.items;
