import React from 'react';
import { Navbar } from 'react-bootstrap';
import nazLogo from '../assets/images/naz-logo-white.svg';

/**
 * Formats a toolbar brand
 *
 * @param {*} props
 * @param {string} props.name - name of the brand
 * @returns {*}
 */
const Brand = (): any => (
  <Navbar.Brand href="/">
    <img src={nazLogo} alt="logo" height={60} width={60} />
  </Navbar.Brand>
);

export default Brand;
