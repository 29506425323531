import React from 'react';
import styles from './TabNavItem.module.css';

const TabNavItem = (props: any) => {
  const handleClick = () => {
    props.setActiveTab(props.id);
  };

  return (
    <div
      className={
        props.activeTab === props.id ? `${styles.active}` : `${styles.normal}`
      }
    >
      <li onClick={handleClick}>{props.title}</li>
    </div>
  );
};
export default TabNavItem;
