import React from 'react';
import {
  Container, Col, Row, Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './Gallery.module.css';
import TabNavItem from '../components/TabNavItem';
import rawGalleryMapping from '../assets/text/galleryMapping.json';

interface GalleryMapping {
  [key: string]: string;
}

const galleryMapping: GalleryMapping = rawGalleryMapping as GalleryMapping;

const Tabs = () => {
  const [key, setKey] = React.useState('All');
  const [showModal, setShowModal] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState('');

  const handleImageClick = (imageUrl: string): void => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  // Function to close the modal
  const handleClose = (): void => {
    setShowModal(false);
  };

  const getFilteredImages = () => {
    const filtered: GalleryMapping = {};
    Object.keys(galleryMapping).forEach((galleryKey) => {
      if (galleryKey.includes(key.toLowerCase())) {
        filtered[galleryKey] = galleryMapping[galleryKey];
      }
    });
    return filtered;
  };

  const imgArray = key.toLowerCase() === 'all' ? galleryMapping : getFilteredImages();

  const displayGallery = Object.keys(imgArray).map((key: string) => (
    <Col key={key} xs={12} md={4}>
      <img
        className={style.imgStyle}
        src={`${process.env.REACT_APP_STATIC_BASE_URI}/${imgArray[key]}`}
        alt={key}
        onClick={() => handleImageClick(
          `${process.env.REACT_APP_STATIC_BASE_URI}/${imgArray[key]}`,
        )
        }
      />
    </Col>
  ));

  return (
    <div className={style.sectionContainer2}>
      <ul className={style.tabContainer}>
        <TabNavItem
          title="All"
          id="All"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Arts"
          id="Arts"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Capture"
          id="Capture"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Characters"
          id="Characters"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Image"
          id="Image"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Monsters"
          id="Monsters"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Props"
          id="Props"
          activeTab={key}
          setActiveTab={setKey}
        />
        <TabNavItem
          title="Storyboards"
          id="Storyboards"
          activeTab={key}
          setActiveTab={setKey}
        />
      </ul>
      <Container>
        <Row>{displayGallery}</Row>
        <Modal show={showModal} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={currentImage} className={style.largeImg} />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

const Gallery = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={style.sectionContainer1}>
        <h1 className={style.title}>{t('nav_gallery')}</h1>
      </div>
      <Tabs />
    </div>
  );
};

export default Gallery;
