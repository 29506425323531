import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router';
import TopNav from './TopNav';

/**
 * Navigation layout
 * @returns {*}
 */
const NavigationLayout = (): any => (
  <Container fluid>
    <TopNav />
    <main>
      <Outlet />
    </main>
  </Container>
);

export default NavigationLayout;
