import React from 'react';
import twitterIcon from '../assets/images/social-x.svg';
import youtubeIcon from '../assets/images/social-youtube.svg';
import igIcon from '../assets/images/social-ig.svg';
import tiktokIcon from '../assets/images/social-tiktok.svg';
import style from './Footer.module.css';

const Footer = () => (
  <footer className={style.footerContainer}>
    <div className={style.iconContainer}>
      <a
        href="https://twitter.com/naz_studio_"
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitterIcon} alt="twitter" />
      </a>
      <a
        href="https://www.youtube.com/@NAZ_Studio_Official"
        target="_blank"
        rel="noreferrer"
      >
        <img src={youtubeIcon} alt="youtube" />
      </a>
      <a
        href="https://www.instagram.com/naz_studio_official/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={igIcon} alt="instagram" />
      </a>
      <a
        href="https://www.tiktok.com/@naz_studio_official"
        target="_blank"
        rel="noreferrer"
      >
        <img src={tiktokIcon} alt="tiktok" />
      </a>
    </div>
    <div>
      <p className={style.paragraphText}>© 2023 NAZ, Co., Ltd.</p>
    </div>
  </footer>
);

export default Footer;
