import partner1 from '../images/collaborators/partner-1.jpg';
import partner2 from '../images/collaborators/partner-2.jpg';
import partner3 from '../images/collaborators/partner-3.jpg';
import partner4 from '../images/collaborators/partner-4.jpg';
import partner5 from '../images/collaborators/partner-5.jpg';
import partner6 from '../images/collaborators/partner-6.jpg';
import partner7 from '../images/collaborators/partner-7.jpg';
import partner8 from '../images/collaborators/partner-8.jpg';
import partner9 from '../images/collaborators/partner-9.jpg';
import partner10 from '../images/collaborators/partner-10.jpg';
import partner11 from '../images/collaborators/partner-11.jpg';

export const collaboratorsConfig = [
  {
    img: `${partner1}`,
  },
  {
    img: `${partner2}`,
  },
  {
    img: `${partner3}`,
  },
  {
    img: `${partner4}`,
  },
  {
    img: `${partner5}`,
  },
  {
    img: `${partner6}`,
  },
  {
    img: `${partner7}`,
  },
  {
    img: `${partner8}`,
  },
  {
    img: `${partner9}`,
  },
  {
    img: `${partner10}`,
  },
  {
    img: `${partner11}`,
  },
];

export default collaboratorsConfig;
