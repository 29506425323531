import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * LoadingSpinner component displays a loading spinner
 * @param props
 * @param props.isLoading - whether to display the spinner,
 * @param props.variant - the spinner color (default is dark)
 * @returns {*}
 */
const LoadingSpinner = (props: any) => (
  <>
    <Spinner
      hidden={!props ? false : !props.isLoading}
      className="loading"
      animation="border"
      variant={!props ? 'dark' : props.variant}
      role="status"
    />
  </>
);

export default LoadingSpinner;
