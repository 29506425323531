import {
  configureStore,
  combineReducers,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line import/no-cycle
import { engageApi } from '../api/engage.api';
// eslint-disable-next-line import/no-cycle
import { authSlice } from './auth.slice';
// eslint-disable-next-line import/no-cycle
import { mediaSlice } from './media.slice';
import { mediaListeners } from '../listeners';

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

// eslint-disable-next-line jest/require-hook
mediaListeners.forEach((listener) => {
  listenerMiddleware.startListening(listener);
});

/**
 * Redux-persist configuration
 */
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'media'],
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  media: mediaSlice.reducer,
  api: engageApi.reducer,
});
// assigns the reducer for presistence, currently presists auth only
const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

/**
 * Redux store configures reducers and RTK API middleware
 */
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .prepend(listenerMiddleware.middleware)
    .concat(engageApi.middleware),
});

// used by router
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
