import React from 'react';
import twitterIcon from '../assets/images/social-x.svg';
import globeIcon from '../assets/images/globe-alt.svg';
import closeBtn from '../assets/images/x-mark.svg';
import style from './WorksModalComponent.module.css';
import { useGetIndividualMediaQuery } from '../api/engage.api';
import ShakaPlayer from './ShakaPlayer';
import { useLocale } from '../hooks/useLocale';

const WorksModalComponent = (props: any) => {
  const { languageCode } = useLocale();
  if (!props.work) return null;

  // call upon the credits for the english works
  const mediaItemId = props.work.id;
  const { data } = useGetIndividualMediaQuery({ id: mediaItemId });

  return (
    <div className={style.myModal} onClick={props.handleClose}>
      <div className={style.closeBtnStyling}>
        <img
          src={closeBtn}
          height={48}
          width={48}
          onClick={props.handleClose}
          className={style.clicked}
        />
      </div>
      <div
        className={style.myModalDiv}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={style.modalStyling}>
          <ShakaPlayer video={props.work.trailer_video_url} />
        </div>
        <div className={style.modalStylingText}>
          <div className={style.modalParagraph}>
            <h2 className={style.modalTitle}>{props.work.title}</h2>
            <p>{props.work.synopsis}</p>
          </div>
          <div className={style.modalCredits}>
            {languageCode === 'en'
              && data?.body.directors
              && data?.body.directors.map((director: any) => (
                <div key={director.id}>
                  <p>
                    {director.role}: {director.full_name}
                  </p>
                </div>
              ))}
            {languageCode === 'ja'
              && props.work.directors
              && props.work.directors.map((director: any) => (
                <div key={director.id}>
                  <p>
                    {director.full_name}
                  </p>
                </div>
              ))}
            {languageCode === 'en'
              && data?.body.producers
              && data?.body.producers.map((producer: any) => (
                <div key={producer.id}>
                  <p>Producer: {producer.full_name}</p>
                </div>
              ))}
            {languageCode === 'ja'
              && props.work.producers
              && props.work.producers.map((producer: any) => (
                <div key={producer.id}>
                  <p>{producer.full_name}</p>
                </div>
              ))}
            {languageCode === 'en'
              && data?.body.staff
              && data?.body.staff.map((staff: any) => (
                <div key={staff.id}>
                  <p>{staff.role}: {staff.full_name}</p>
                </div>
              ))}
            {languageCode === 'ja'
              && props.work.staff
              && props.work.staff.map((staff: any) => (
                <div key={staff.id}>
                  <p>{staff.full_name}</p>
                </div>
              ))}
            <div className={style.links}>
              {props.work.official_twitter_url && (
                <a
                  href={props.work.official_twitter_url}
                  target="_blank"
                  key={props.work.official_twitter_url}
                  style={{ marginRight: '48px' }}
                  rel="noreferrer"
                >
                  <img
                    src={twitterIcon}
                    alt={`${props.work.title} Twitter`}
                    width={24}
                    height={24}
                  />
                </a>
              )}
              {props.work.official_url && (
                <a
                  href={props.work.official_url}
                  target="_blank"
                  key={props.work.official_url}
                  style={{ marginRight: '48px' }}
                  rel="noreferrer"
                >
                  <img
                    src={globeIcon}
                    alt={`${props.work.title} official website`}
                    width={24}
                    height={24}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksModalComponent;
