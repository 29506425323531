import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import staffConfig from '../assets/text/staffConfig';
import downArrow from '../assets/images/chevron-down.svg';
import styles from './AccordionComponent.module.css';

// AccordionItem component
const AccordionItem = (props: any) => {
  const contentHeight = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (contentHeight.current && props.isOpen) {
      contentHeight.current.style.height = `${contentHeight.current.scrollHeight}px`;
    } else if (contentHeight.current) {
      contentHeight.current.style.height = '0px';
    }
  }, [props.isOpen]);

  return (
    <div className={`${styles.wrapper}`}>
      <button
        className={`${styles.questionContainer} ${
          props.isOpen ? 'active' : ''
        }`}
        onClick={props.onClick}
      >
        <img src={props.img} alt={props.name} width={256} height={256} />
        <h2 className={styles.nameTitle}>{t(`name.${props.name}`)}</h2>
        <p className={styles.position}>{t(`position.${props.position}`)}</p>
        <img
          src={downArrow}
          height={48}
          width={48}
          className={`${styles.arrow} ${props.isOpen ? 'active' : ''}`}
        />
      </button>

      <div ref={contentHeight} className={`${styles.answerContainer}`}>
        <p className={`${styles.answerContent}`}>
          {props.involvement.map((item: any) => (
            <div key={item.anime_title}>
              <p className={styles.animeTitle}>
                {t(`anime.${item.anime_title}`)}
              </p>
              <p className={styles.animeTitleRole}>{t(`role.${item.role}`)}</p>
            </div>
          ))}
        </p>
      </div>
    </div>
  );
};

// Main AccordionComponent
const AccordionComponent = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={`${styles.gridContainer}`}>
      {staffConfig.map((item: any, index: number) => (
        <AccordionItem
          key={index}
          img={item.img}
          name={item.name}
          position={item.position}
          involvement={item.involvement}
          answer={item.answer}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </div>
  );
};

export default AccordionComponent;
