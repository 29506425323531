/* eslint-disable jest/require-hook */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    resources: {
      en: {
        translations: {
          btn_viewAll: 'VIEW ALL',
          home_title1: 'BRINGING A',
          home_title2: 'UNIQUE EDGE TO ANIME',
          'prizes.2019-2020 NewType Anime Award': '2019-2020 NewType Anime Award',
          'prizes.Best Sound No. 1': 'Best Sound No. 1',
          'prizes.Best Studio No. 2': 'Best Studio No. 2',
          'prizes.Best Animation No. 2': 'Best Animation No. 2',
          contact_companyName: 'Company Name',
          contact_Establishment: 'Establishment',
          contact_Representative: 'Representative',
          contact_Email: 'Email',
          contact_companyNameInfo: 'NAZ Co., Ltd.',
          contact_EstablishmentInfo: 'Apr 19, 2013',
          contact_RepresentativeInfo: 'Yasuo Suda',
          title_works: 'WORKS',
          title_aboutNAZ: 'ABOUT NAZ',
          title_collaborators: 'COLLABORATORS',
          title_contactUs: 'CONTACT US',
          title_awards: 'AWARDS',
          title_staff: 'STAFF',
          nav_about: 'ABOUT',
          nav_works: 'WORKS',
          nav_gallery: 'GALLERY',
          nav_culture: 'CULTURE',
          nav_contact: 'CONTACT',
          homepagePara1: 'Composed of Triangles.',
          homepagePara2: 'Making Works with Edge.',
          homepagePara3: 'Sharpening and Polishing What We Love.',
          homepagePara4: 'NAZ is an entertainment, art, and content production studio founded by Yasuo Suda.',
          homepagePara5: 'Since its establishment in 2013, the studio\'s initial ventures included crafting original anime such as Hamatora and Tokunana, alongside adaptations like Angolmois: Genko Kassen-Ki and Infinite Dendrogram. Elevating NAZ\'s global acclaim was the release of ID:INVADED, earning widespread recognition, particularly in Asia. The prestigious Newtype Anime Award Sound Award attested to its success. Further solidifying NAZ\'s influence, a strategic partnership with Netflix opened new frontiers. The partnership marked a pivotal moment, propelling several works such as Thermae Romae Novae and Good Night World to rank in the top 5 on Netflix’s daily charts.',
          homepagePara6: 'NAZ Studio continues to redefine the anime landscape, seamlessly blending tradition with innovation.',
          aboutPagePara1: 'Delve into the roots of ANIMA&COMPANY (NAZ) and witness the visionary tale of its founder, Yasuo Suda. Formerly immersed in a studio specializing in children\'s anime like Keroro and Mitsudomoe, Suda\'s passion for anime extended to the likes of AKIRA. Notably, he contributed to the "Megami Tensei Series Devil Survivor 2," showcasing his versatile prowess.',
          aboutPagePara2: 'In 2013, fueled by a desire for independence, Suda founded NAZ. The studio\'s initial ventures included crafting original anime such as "Hamatora" and "Tokunana," alongside adaptations like "Angolmois: Genkō Kassen-ki" and "Infinite Dendrogram."',
          aboutPagePara3: 'A driving force behind NAZ\'s genesis was Suda\'s unease with the traditional anime production model, heavily reliant on outsourced animators. This concern led to the inception of the innovative "Guild System," fostering in-house anime production—a paradigm shift in the industry.',
          aboutPagePara4: 'Elevating NAZ\'s global acclaim was the release of "ID:INVADED," earning widespread recognition, particularly in Asia. The prestigious NewType Anime Award Sound Award attested to its success. Further solidifying NAZ\'s influence, a strategic partnership with Netflix opened new frontiers.',
          aboutPagePara5: '"Thermae Romae Novae" stormed Netflix\'s daily chart, claiming a spot in the top 5. This triumph was followed by the production of "Sabikui Bisco" and the original animation "Rhapsody." The enchanting "Good Night World" secured a spot in Netflix\'s top 4, underscoring NAZ\'s storytelling finesse.',
          aboutPagePara6: 'As a grand celebration of NAZ\'s tenth anniversary, the studio unveiled the captivating "Special Kid Factory," epitomizing a decade of creative brilliance.',
          aboutPagePara7: 'ANIMA&COMPANY (NAZ) continues to redefine the anime landscape, seamlessly blending tradition with innovation.',
          awardName: '2019-2020 NewType Anime Award',
          awardTitle1: 'Best Sound No. 1',
          awardTitle2: 'Best Studio No. 2',
          awardTitle3: 'Best Animation No. 2',
          'anime.Terra Formars: Revenge': 'Terra Formars: Revenge',
          'anime.Re:ZERO -Starting Life in Another World-': 'Re:ZERO -Starting Life in Another World-',
          'anime.Fate/stay night: Heaven\'s Feel III. spring song': 'Fate/stay night: Heaven\'s Feel III. spring song',
          'anime.Fate/stay night Movie: Heaven\'s Feel - I. presage flower': 'Fate/stay night Movie: Heaven\'s Feel - I. presage flower',
          'anime.Fate/stay night Movie: Heaven\'s Feel - I. Presage Flower': 'Fate/stay night Movie: Heaven\'s Feel - I. Presage Flower',
          'anime.ID:INVADED': 'ID:INVADED',
          'anime.Sabikui Bisco': 'Sabikui Bisco',
          'anime.Hamatora': 'Hamatora',
          'anime.Angolmois: Record of Mongol Invasion': 'Angolmois: Record of Mongol Invasion',
          'anime.Devilman Crybaby': 'Devilman Crybaby',
          'anime.Super Shiro': 'Super Shiro',
          'anime.Japan Sinks: 2020': 'Japan Sinks: 2020',
          'anime.Japan Sinks: 2020 Theatrical Edition': 'Japan Sinks: 2020 Theatrical Edition',
          'anime.Thermae Romae Novae': 'Thermae Romae Novae',
          'anime.Shōnen Hollywood - Holly Stage for 49': 'Shōnen Hollywood - Holly Stage for 49',
          'anime.Shōnen Hollywood - Holly Stage for 50': 'Shōnen Hollywood - Holly Stage for 50',
          'anime.The Great Passage': 'The Great Passage',
          'anime.Special 7: Special Crime Investigation Unit': 'Special 7: Special Crime Investigation Unit',
          'anime.Backflip!!': 'Backflip!!',
          'anime.Backflip!! Movie': 'Backflip!! Movie',
          'anime.Grimoire of Zero': 'Grimoire of Zero',
          'anime.Goblin Slayer': 'Goblin Slayer',
          'anime.Infinite Dendrogram': 'Infinite Dendrogram',
          'anime.Pokémon: Twilight Wings': 'Pokémon: Twilight Wings',
          'anime.Music Video "Mr. Fixer"': 'Music Video "Mr. Fixer"',
          'anime.The IdolM@ster Cinderella Girls 10th Anniversary Celebration Animation "ETERNITY MEMORIES"': 'The IdolM@ster Cinderella Girls 10th Anniversary Celebration Animation "ETERNITY MEMORIES"',
          'anime.The IdolM@ster Cinderella Girls: U149': 'The IdolM@ster Cinderella Girls: U149',
          'anime.Pokémon 3 the Movie: Spell of the Unown': 'Pokémon 3 the Movie: Spell of the Unown',
          'anime.Pokémon 4Ever': 'Pokémon 4Ever',
          'anime.Code Geass: Lelouch of the Rebellion': 'Code Geass: Lelouch of the Rebellion',
          'anime.Evangelion: 1.0 You Are (Not) Alone': 'Evangelion: 1.0 You Are (Not) Alone',
          'anime.Evangelion: 2.0 You Can (Not) Advance': 'Evangelion: 2.0 You Can (Not) Advance',
          'anime.Fate/Zero': 'Fate/Zero',
          'anime.Evangelion: 3.0 You Can (Not) Redo': 'Evangelion: 3.0 You Can (Not) Redo',
          'anime.The Devil Is a Part-Timer!': 'The Devil Is a Part-Timer!',
          'anime.Busou Shinki': 'Busou Shinki',
          'anime.Attack on Titan Season 3': 'Attack on Titan Season 3',
          'anime.Fate/stay night: Heaven\'s Feel II. Lost Butterfly': 'Fate/stay night: Heaven\'s Feel II. Lost Butterfly',
          'anime.Fate/stay night: Heaven\'s Feel III. Spring Song': 'Fate/stay night: Heaven\'s Feel III. Spring Song',
          'anime.Good Night World': 'Good Night World',
          'anime.Howl\'s Moving Castle': 'Howl\'s Moving Castle',
          'anime.Pokémon the Movie: The Power of Us': 'Pokémon the Movie: The Power of Us',
          'anime.Attack on Titan': 'Attack on Titan',
          'anime.Jujutsu Kaisen 0': 'Jujutsu Kaisen 0',
          'anime.SPY×FAMILY': 'SPY×FAMILY',
          'anime.Yowamushi Pedal: Limit Break': 'Yowamushi Pedal: Limit Break',
          'anime.Bleach: Thousand-Year Blood War': 'Bleach: Thousand-Year Blood War',
          'anime.Persona 4: The Animation': 'Persona 4: The Animation',
          'anime.Devil Survivor 2: The Animation': 'Devil Survivor 2: The Animation',
          'anime.Mobile Suit Gundam: Iron-Blooded Orphans': 'Mobile Suit Gundam: Iron-Blooded Orphans',
          'anime.Code Geass: Lelouch of the Resurrection': 'Code Geass: Lelouch of the Re;surrection',
          'anime.Rhapsody': 'Rhapsody',
          'anime.Ghost in the Shell: Stand Alone Complex': 'Ghost in the Shell: Stand Alone Complex',
          'anime.Fullmetal Alchemist': 'Fullmetal Alchemist',
          'anime.Afro Samurai': 'Afro Samurai',
          'anime.The Royal Tutor': 'The Royal Tutor',
          'anime.Music Video "Bin To Squall"': 'Music Video "Bin To Squall"',
          'anime.Aldnoah.Zero': 'Aldnoah.Zero',
          'anime.Promotion Video for "Match Shōjo"': 'Promotion Video for "Match Shōjo"',
          'anime."Kemurika Tsuchika Kuimono" (Smoke, Soil, or Prey)': '"Kemurika Tsuchika Kuimono" (Smoke, Soil, or Prey)',
          'anime."Kuma no Basho" (Place of the Bear)': '"Kuma no Basho" (Place of the Bear)',
          'anime."Suki Suki Daisuki Cho Aishiteru" (Love, Love, Love You, I Love You.)': '"Suki Suki Daisuki Cho Aishiteru" (Love, Love, Love You, I Love You.)',
          'anime.The Dragon Dentist': 'The Dragon Dentist',
          'anime.Opening for "Sabikui Bisco"': 'Opening for "Sabikui Bisco"',
          'anime.Naruto': 'Naruto',
          'anime.Gurren Lagann': 'Gurren Lagann',
          'anime.The Garden of Sinners': 'The Garden of Sinners',
          'anime.Code Geass: Lelouch of the Rebellion R2': 'Code Geass: Lelouch of the Rebellion R2',
          'anime.Doraemon the Movie: Nobita\'s Spaceblazer': 'Doraemon the Movie: Nobita\'s Spaceblazer',
          'anime.Doraemon: Nobita\'s Great Battle of the Mermaid King': 'Doraemon: Nobita\'s Great Battle of the Mermaid King',
          'anime.Fullmetal Alchemist: The Sacred Star of Milos': 'Fullmetal Alchemist: The Sacred Star of Milos',
          'anime.Patema Inverted': 'Patema Inverted',
          'anime.Doraemon the Movie: Nobita\'s Treasure Island': 'Doraemon the Movie: Nobita\'s Treasure Island',
          'anime.Eureka Seven: Good Night, Sleep Tight, Young Lovers': 'Eureka Seven: Good Night, Sleep Tight, Young Lovers',
          'anime.The Nutcracker': 'The Nutcracker',
          'anime.Food Wars!: Shokugeki no Soma': 'Food Wars!: Shokugeki no Soma',
          'anime.Re:CREATORS': 'Re:CREATORS',
          'anime.My Hero Academia': 'My Hero Academia',
          'anime.Jujutsu Kaisen': 'Jujutsu Kaisen',
          'anime.Idol Memories': 'Idol Memories',
          'anime.Sengoku Night Blood': 'Sengoku Night Blood',
          'anime.The Royal Tutor Movie - Unknown': 'The Royal Tutor Movie - Unknown',
          'anime.Fairy Tail': 'Fairy Tail',
          'anime.Mr. Osomatsu': 'Mr. Osomatsu',
          'anime.Uta no Prince Sama: Legend Star': 'Uta no Prince Sama: Legend Star',
          'anime.Shaman King': 'Shaman King',
          'anime.My Teen Romantic Comedy SNAFU TOO!': 'My Teen Romantic Comedy SNAFU TOO!',
          'anime.Sergeant Keroro': 'Sergeant Keroro',
          'anime.Code Geass: Akito the Exiled': 'Code Geass: Akito the Exiled',
          'anime.Hyperdimension Neptunia: The Animation': 'Hyperdimension Neptunia: The Animation',
          'anime.Planetarian: The Reverie of a Little Planet': 'Planetarian: The Reverie of a Little Planet',
          'anime.Planetarian: Storyteller of the Stars': 'Planetarian: Storyteller of the Stars',
          'anime.My Hero Academia Season 3': 'My Hero Academia Season 3',
          'anime.My Hero Academia Season 4': 'My Hero Academia Season 4',
          'anime.One Piece': 'One Piece',
          'anime.Pokémon the Movie: I Choose You!': 'Pokémon the Movie: I Choose You!',
          'anime.The Seven Deadly Sins: Imperial Wrath of The Gods': 'The Seven Deadly Sins: Imperial Wrath of The Gods',
          'anime.One Punch Man Season 2': 'One Punch Man Season 2',
          'anime.The Seven Deadly Sins: Dragon\'s Judgement': 'The Seven Deadly Sins: Dragon\'s Judgement',
          'anime.Gloomy the Naughty Grizzly': 'Gloomy the Naughty Grizzly',
          'name.Otaro Maijo': 'Otaro Maijo',
          'name.Atsushi Ikariya': 'Atsushi Ikariya',
          'name.Daisuke Mataga': 'Daisuke Mataga',
          'name.Takayuki Kuriyama': 'Takayuki Kuriyama',
          'name.Takahiro Hondai': 'Takahiro Hondai',
          'name.Ai Asari': 'Ai Asari',
          'name.Haruka Itou': 'Haruka Itou',
          'name.Katsuya Kikuchi': 'Katsuya Kikuchi',
          'name.Kazuo Takigawa': 'Kazuo Takigawa',
          'name.Shouta Yoshida': 'Shouta Yoshida',
          'name.Tasuku Takagi': 'Tasuku Takagi',
          'name.Toshikazu Hisano': 'Toshikazu Hisano',
          'name.Kunihiko Mita': 'Kunihiko Mita',
          'name.Momoko Kawai': 'Momoko Kawai',
          'name.Shingo Nakamura': 'Shingo Nakamura',
          'name.Rena Okuyama': 'Rena Okuyama',
          'name.Norie Igawa': 'Norie Igawa',
          'name.Haruka Sanefuji': 'Haruka Sanefuji',
          'name.Yukie Kaneko': 'Yukie Kaneko',
          'name.Hitomi Takechi': 'Hitomi Takechi',
          'position.Novelist': 'Novelist',
          'position.Director, Animator': 'Director, Animator',
          'position.Director': 'Director',
          'position.Compositing Director': 'Compositing Director',
          'position.Character Designer, Animator': 'Character Designer, Animator',
          'position.Animator': 'Animator',
          'position.3D Director': '3D Director',
          'position.Character Designer/Animator': 'Character Designer/Animator',
          'position.Animation Director': 'Animation Director',
          'role.Assistant Director': 'Assistant Director',
          'role.Animation Director': 'Animation Director',
          'role.Key Animator': 'Key Animator',
          'role.Main Animator, Animation Director': 'Main Animator, Animation Director',
          'role.Key Animation Director Cooperation': 'Key Animation Director Cooperation',
          'role.Character Designer': 'Character Designer',
          'role.Assistant Animation Director': 'Assistant Animation Director',
          'role.Animation Director, Assistant Animation Director': 'Animation Director, Assistant Animation Director',
          'role.Animation Director, Key Animation Supervisor, Key Animation Cooperation': 'Animation Director, Key Animation Supervisor, Key Animation Cooperation',
          'role.In-Between Animator': 'In-Between Animator',
          'role.In-Between Animation Assistant Inspector': 'In-Between Animation Assistant Inspector',
          'role.In-Between Animation Inspector': 'In-Between Animation Inspector',
          'role.Assistant Chief Animation Director': 'Assistant Chief Animation Director',
          'role.Subcharacter Designer': 'Subcharacter Designer',
          'role.Chief Animation Director': 'Chief Animation Director',
          'role.Character Designer, Animation Director': 'Character Designer, Animation Director',
          'role.Character Design Cooperation, Animation Director': 'Character Design Cooperation, Animation Director',
          'role.Character Designer, Chief Animation Director, Animation Director': 'Character Designer, Chief Animation Director, Animation Director',
          'role.Animation Character Designer, Chief Animation Director, Animation Director for Live Part, Animation Director, Assistant Animation Director': 'Animation Character Designer, Chief Animation Director, Animation Director for Live Part, Animation Director, Assistant Animation Director',
          'role.Character Animation Director': 'Character Animation Director',
          'role.Monitor Works': 'Monitor Works',
          'role.3D Director': '3D Director',
          'role.CG Director': 'CG Director',
          'role.Compositing Director': 'Compositing Director',
          'role.Assistant Compositing Director': 'Assistant Compositing Director',
          'role.3D CGI': '3D CGI',
          'role.Compositing Staff': 'Compositing Staff',
          'role.Episode Director, Animation Director': 'Episode Director, Animation Director',
          'role.Episode Director, Storyboard, Animation Director, Plop Designer': 'Episode Director, Storyboard, Animation Director, Plop Designer',
          'role.Action Animation Director': 'Action Animation Director',
          'role.Plop Designer, Animation Director, Assistant Chief Animation Director': 'Plop Designer, Animation Director, Assistant Chief Animation Director',
          'role.Episode Director': 'Episode Director',
          'role.Chief Director, Episode Director': 'Chief Director, Episode Director',
          'role.Director': 'Director',
          'role.Director, Storyboard, Episode Director, Line Producer, Production Coordinator': 'Director, Storyboard, Episode Director, Line Producer, Production Coordinator',
          'role.Main Animator, Storyboard, Episode Director, Animation Director': 'Main Animator, Storyboard, Episode Director, Animation Director',
          'role.Key Animator, Animation Director': 'Key Animator, Animation Director',
          'role.Character Designer, Chief Animation Director, Storyboard Artist, Episode Directror, Animation Director': 'Character Designer, Chief Animation Director, Storyboard Artist, Episode Directror, Animation Director',
          'role.Original Work, Scriptwriter': 'Original Work, Scriptwriter',
          'role.Series Organization, Scriptwriter': 'Series Organization, Scriptwriter',
          'role.Storyboard': 'Storyboard',
          lc_en: 'EN',
          lc_ja: 'JP',
        },
      },
      ja: {
        translations: {
          btn_viewAll: 'すべてを表示',
          home_title1: 'アニメの',
          home_title2: '新たな角度を追い求めて',
          title_works: '作品',
          title_aboutNAZ: '会社概要',
          title_collaborators: '取引先',
          title_contactUs: 'お問い合わせ',
          title_awards: '受賞歴',
          title_staff: 'スタッフ',
          'prizes.2019-2020 NewType Anime Award': '2019-2020 ニュータイプアニメアワード',
          'prizes.Best Sound No. 1': 'ベストサウンド No. 1',
          'prizes.Best Studio No. 2': 'ベストスタジオ No. 2',
          'prizes.Best Animation No. 2': 'ベストアニメーション No. 2',
          contact_companyName: '会社名',
          contact_Establishment: '設立',
          contact_Representative: '代表者',
          contact_Email: 'メールアドレス',
          contact_companyNameInfo: '株式会社アニマ＆カンパニー',
          contact_EstablishmentInfo: '2013年4月19日',
          contact_RepresentativeInfo: '須田泰雄',
          nav_about: '会社概要',
          nav_works: '作品',
          nav_gallery: 'ギャラリー',
          nav_culture: '社風',
          nav_contact: 'お問い合わせ',
          homepagePara1: '三角形で構成されている。',
          homepagePara2: '尖った作品を制作する。',
          homepagePara3: '自分たちが好きなものを研ぎ澄ましていく。',
          homepagePara4: 'NAZ(創業者：須田泰雄)はエンターテイメント、アート、コンテンツ制作スタジオとしてオリジナルアニメの制作と物語のアニメ化を行います。',
          homepagePara5: '2013年の設立以降「ハマトラ」「トクナナ」のオリジナルアニメに加え「アンゴルモア元寇合戦記」「インフィニット・デンドログラム」等の漫画や小説を原作とする作品の制作も行いました。オリジナルアニメ「ID:INVADED」はアジアを中心に全世界的に評価を得てニュータイプアニメアワードサウンド賞を受賞しました。Netflixと包括的業務提携を結び、物語を世界に届ける能力を拡大しました。Netflixで公開された「テルマエ・ロマエ ノヴァエ」「グッド・ナイト・ワールド」はともに「今日の総合」でTOP5以内にランクインしました。',
          homepagePara6: '伝統に根ざして革新を起こし、アニメの再定義を行います。',
          aboutPagePara1: '創業者である須田泰雄は「ケロロ軍曹」「みつどもえ」等の子供向けアニメのスタジオで修行を積みました。「AKIRA」のようなアニメが好きなこともあり「女神転生シリーズ デビルサバイバー２」をプロデュースしました。',
          aboutPagePara2: '2013年に独立しNAZを設立。「ハマトラ」「トクナナ」のオリジナル作品に加え「アンゴルモア元寇合戦記」「インフィニット・デンドログラム」等の漫画や小説を原作とする作品の制作も行いました。',
          aboutPagePara3: '旧来型のアウトソーシング中心のアニメーション技法と現状のアニメーション産業の実情の乖離に苦悩し社内で人材を育成する「ギルドシステム」を開発。インハウスでのアニメーション制作で業界に一石を投じます。',
          aboutPagePara4: 'オリジナルアニメ「ID:INVADED」はアジアを中心に全世界的に評価を得てニュータイプアニメアワードサウンド賞を受賞しました。Netflixと包括的業務提携を結び、物語を世界に届ける能力を拡大しました。',
          aboutPagePara5: '「テルマエ・ロマエ ノヴァエ」はNetflix今日の総合TOP10にて5位にランクイン。近年は「錆喰いビスコ」、オリジナルアニメ「ラプソディー」を制作。「グッド・ナイト・ワールド」はNetflix今日の総合TOP10にて4位にランクインしました。',
          aboutPagePara6: 'NAZ創立10周年作品として「Special Kid Factory」の制作を開始しました。',
          aboutPagePara7: '伝統に根ざして革新を起こし、アニメの再定義を行います',
          awardName: '2019-2020 ニュータイプ アニメアワード',
          awardTitle1: 'サウンド賞1位',
          awardTitle2: 'スタジオ賞2位',
          awardTitle3: '作品賞2位',
          'anime.Terra Formars: Revenge': 'TERRAFORMARS REVENGE',
          'anime.Re:ZERO -Starting Life in Another World-': 'Re:ゼロから始める異世界⽣活',
          'anime.Fate/stay night: Heaven\'s Feel III. spring song': '劇場版Fate/stay night [Heaven‘s Feel] 第3章',
          'anime.Fate/stay night Movie: Heaven\'s Feel - I. presage flower': '劇場版Fate/stay night [Heaven‘s Feel] 第1章',
          'anime.Fate/stay night Movie: Heaven\'s Feel - I. Presage Flower': '劇場版Fate/stay night [Heaven‘s Feel] 第1章',
          'anime.ID:INVADED': 'ID:INVADED',
          'anime.Sabikui Bisco': '錆喰いビスコ',
          'anime.Hamatora': 'ハマトラ',
          'anime.Angolmois: Record of Mongol Invasion': 'アンゴルモア元寇合戦記',
          'anime.Devilman Crybaby': 'DEVILMAN crybaby',
          'anime.Super Shiro': 'SUPER SHIRO',
          'anime.Japan Sinks: 2020': '⽇本沈没2020',
          'anime.Japan Sinks: 2020 Theatrical Edition': '⽇本沈没2020 劇場版-シズマヌキボウ-',
          'anime.Thermae Romae Novae': 'テルマエ・ロマエノヴァエ',
          'anime.Shōnen Hollywood - Holly Stage for 49': '少年ハリウッド -HOLLY STAGE FOR 49-',
          'anime.Shōnen Hollywood - Holly Stage for 50': '少年ハリウッド -HOLLY STAGE FOR 50-',
          'anime.The Great Passage': '舟を編む',
          'anime.Special 7: Special Crime Investigation Unit': '警視庁 特務部 特殊凶悪犯対策室 第七課 -トクナナ-',
          'anime.Backflip!!': 'バクテン!!',
          'anime.Backflip!! Movie': '映画 バクテン!!',
          'anime.Grimoire of Zero': 'ゼロから始める魔法の書',
          'anime.Goblin Slayer': 'ゴブリンスレイヤー',
          'anime.Infinite Dendrogram': 'インフィニット・デンドログラム',
          'anime.Pokémon: Twilight Wings': '薄明の翼',
          'anime.Music Video "Mr. Fixer"': 'ミスターフィクサー',
          'anime.The IdolM@ster Cinderella Girls 10th Anniversary Celebration Animation "ETERNITY MEMORIES"': 'CINDERELLA GIRLS 10th Anniversary Celebration Animation「ETERNITY MEMORIES」',
          'anime.The IdolM@ster Cinderella Girls: U149': 'アイドルマスターシンデレラガールズ U149',
          'anime.Pokémon 3 the Movie: Spell of the Unown': '劇場版ポケットモンスター 結晶塔の帝王 ENTEI',
          'anime.Pokémon 4Ever': '劇場版ポケットモンスター セレビィ　時を越えた遭遇',
          'anime.Code Geass: Lelouch of the Rebellion': 'コードギアス反逆のルルーシュ',
          'anime.Evangelion: 1.0 You Are (Not) Alone': 'ヱヴァンゲリヲン新劇場版:序',
          'anime.Evangelion: 2.0 You Can (Not) Advance': 'ヱヴァンゲリヲン新劇場版:破',
          'anime.Fate/Zero': 'Fate/Zero',
          'anime.Evangelion: 3.0 You Can (Not) Redo': 'ヱヴァンゲリヲン新劇場版:Q',
          'anime.The Devil Is a Part-Timer!': 'はたらく魔王さま！',
          'anime.Busou Shinki': '武装神姫',
          'anime.Attack on Titan Season 3': '進撃の巨人 3ndシーズン',
          'anime.Fate/stay night: Heaven\'s Feel II. Lost Butterfly': 'F劇場版Fate/stay night [Heaven‘s Feel] 第2章',
          'anime.Fate/stay night: Heaven\'s Feel III. Spring Song': '劇場版Fate/stay night [Heaven‘s Feel] 第3章',
          'anime.Good Night World': 'グッド・ナイト・ワールド',
          'anime.Howl\'s Moving Castle': 'ハウルの動く城',
          'anime.Pokémon the Movie: The Power of Us': '劇場版ポケットモンスターみんなの物語',
          'anime.Attack on Titan': '進撃の巨人Season2、3',
          'anime.Jujutsu Kaisen 0': '劇場版呪術廻戦0',
          'anime.SPY×FAMILY': 'SPY×FAMILY',
          'anime.Yowamushi Pedal: Limit Break': '弱虫ペダルLIMIT BREAK',
          'anime.Bleach: Thousand-Year Blood War': 'BLEACH千年血戦篇(訣別譚)',
          'anime.Persona 4: The Animation': 'ペルソナ4',
          'anime.Devil Survivor 2: The Animation': 'DEVIL SURVIVOR2',
          'anime.Mobile Suit Gundam: Iron-Blooded Orphans': '機動戦⼠ガンダム鉄⾎のオルフェンズ',
          'anime.Code Geass: Lelouch of the Resurrection': 'コードギアス反逆のルルーシュ',
          'anime.Rhapsody': 'Rhapsody',
          'anime.Ghost in the Shell: Stand Alone Complex': 'Ghost in the Shell: Stand Alone Complex',
          'anime.Fullmetal Alchemist': '鋼の錬金術師',
          'anime.Afro Samurai': 'アフロサムライ',
          'anime.The Royal Tutor': '王室教師ハイネ',
          'anime.Music Video "Bin To Squall"': '瓶とスコール』のMV',
          'anime.Aldnoah.Zero': 'アルドノア・ゼロ',
          'anime.Promotion Video for "Match Shōjo"': '燐寸少女',
          'anime."Kemurika Tsuchika Kuimono" (Smoke, Soil, or Prey)': '煙か⼟か⾷い物',
          'anime."Kuma no Basho" (Place of the Bear)': '熊の場所',
          'anime."Suki Suki Daisuki Cho Aishiteru" (Love, Love, Love You, I Love You.)': '好き好き⼤好き超愛してる',
          'anime.The Dragon Dentist': '⿓の⻭医者',
          'anime.Opening for "Sabikui Bisco"': '錆喰いビスコ',
          'anime.Naruto': 'NARUTO -ナルト-',
          'anime.Gurren Lagann': '天元突破グレンラガン',
          'anime.The Garden of Sinners': '空の境界',
          'anime.Code Geass: Lelouch of the Rebellion R2': 'コードギアス 反逆のルルーシュR2',
          'anime.Doraemon the Movie: Nobita\'s Spaceblazer': '劇場版ドラえもん新・のび太の宇宙開拓史',
          'anime.Doraemon: Nobita\'s Great Battle of the Mermaid King': '劇場版ドラえもんのび太の⼈⿂⼤海戦',
          'anime.Fullmetal Alchemist: The Sacred Star of Milos': '鋼の錬⾦術師嘆きの丘（ミロス）の聖なる星',
          'anime.Patema Inverted': 'サカサマのパテマ',
          'anime.Doraemon the Movie: Nobita\'s Treasure Island': '映画ドラえもんのび太の宝島',
          'anime.Eureka Seven: Good Night, Sleep Tight, Young Lovers': '劇場版交響詩篇エウレカセブンポケットが虹でいっぱい',
          'anime.The Nutcracker': '劇場版くるみ割り⼈形',
          'anime.Food Wars!: Shokugeki no Soma': '⾷戟のソーマ',
          'anime.Re:CREATORS': 'Re:CREATORS',
          'anime.My Hero Academia': '僕のヒーローアカデミア',
          'anime.Jujutsu Kaisen': '呪術廻戦',
          'anime.Idol Memories': 'アイドルメモリーズ',
          'anime.Sengoku Night Blood': '戦刻ナイトブラッド',
          'anime.The Royal Tutor Movie - Unknown': '王室教師ハイネ',
          'anime.Fairy Tail': 'FAIRY TAIL',
          'anime.Mr. Osomatsu': 'おそ松さん',
          'anime.Uta no Prince Sama: Legend Star': 'うたの☆プリンスさまっ♪マジLOVEレジェンドスター',
          'anime.Shaman King': 'SHAMAN KING',
          'anime.My Teen Romantic Comedy SNAFU TOO!': 'やはり俺の⻘春ラブコメは間違っている。続 ',
          'anime.Sergeant Keroro': 'ケロロ軍曹',
          'anime.Code Geass: Akito the Exiled': 'コードギアス 亡国のアキト',
          'anime.Hyperdimension Neptunia: The Animation': '超次元ゲイム ネプテューヌ THE ANIMATION',
          'anime.Planetarian: The Reverie of a Little Planet': 'planetarian～ちいさなほしのゆめ～',
          'anime.Planetarian: Storyteller of the Stars': '劇場版 planetarian～星の人～',
          'anime.My Hero Academia Season 3': '僕のヒーローアカデミア (3rd season)',
          'anime.My Hero Academia Season 4': '僕のヒーローアカデミア (4th season)',
          'anime.One Piece': 'ONE PIECE',
          'anime.Pokémon the Movie: I Choose You!': '劇場版ポケットモンスター キミにきめた!',
          'anime.The Seven Deadly Sins: Imperial Wrath of The Gods': '七つの⼤罪神々の逆鱗',
          'anime.One Punch Man Season 2': 'ワンパンマン (SEASON2)',
          'anime.The Seven Deadly Sins: Dragon\'s Judgement': '七つの⼤罪憤怒の審判',
          'anime.Gloomy the Naughty Grizzly': 'いたずらぐまのグル〜ミ〜',
          'name.Otaro Maijo': '舞城王太郎',
          'name.Atsushi Ikariya': '碇谷敦',
          'name.Daisuke Mataga': '又賀大介',
          'name.Takayuki Kuriyama': '栗⼭貴⾏',
          'name.Takahiro Hondai': '本台貴宏',
          'name.Ai Asari': '浅利歩惟',
          'name.Haruka Itou': '伊藤遼',
          'name.Katsuya Kikuchi': '菊池カツヤ',
          'name.Kazuo Takigawa': '滝川和男',
          'name.Shouta Yoshida': '吉⽥匠汰',
          'name.Tasuku Takagi': '⾼⽊翼',
          'name.Toshikazu Hisano': '久野利和',
          'name.Kunihiko Mita': '三田邦彦',
          'name.Momoko Kawai': '河合桃子',
          'name.Shingo Nakamura': '中村慎吾',
          'name.Rena Okuyama': '奥山鈴奈',
          'name.Norie Igawa': '井川典恵',
          'name.Haruka Sanefuji': '實藤晴香',
          'name.Yukie Kaneko': '金子由紀江',
          'name.Hitomi Takechi': '竹知仁美',
          'position.Novelist': '小説家',
          'position.Director, Animator': 'ディレクター・アニメーター',
          'position.Director': 'ディレクター',
          'position.Compositing Director': '撮影監督',
          'position.Character Designer, Animator': 'キャラクターデザイン・アニメーター',
          'position.Animator': 'アニメーター',
          'position.3D Director': '3Dディレクター',
          'position.Character Designer/Animator': 'キャラクターデザイン・アニメーター',
          'position.Animation Director': '動画監督',
          'role.Assistant Director': '副監督',
          'role.Animation Director': '作画監督',
          'role.Key Animator': '原画',
          'role.Main Animator, Animation Director': 'メインアニメーター、 作画監督',
          'role.Key Animation Director Cooperation': '作画監督協力',
          'role.Character Designer': 'キャラクターデザイン',
          'role.Assistant Animation Director': '作画監督補佐',
          'role.Animation Director, Assistant Animation Director': '作画監督、 作画監督補佐',
          'role.Animation Director, Key Animation Supervisor, Key Animation Cooperation': '作画監督、 作画監修、 作画監督協力',
          'role.In-Between Animator': '動画',
          'role.In-Between Animation Assistant Inspector': '動画検査補佐',
          'role.In-Between Animation Inspector': '動画検査',
          'role.Assistant Chief Animation Director': '総作画監督補佐',
          'role.Subcharacter Designer': 'サブキャラクターデザイン',
          'role.Chief Animation Director': '総作画監督',
          'role.Character Designer, Animation Director': 'キャラクターデザイン、作画監督',
          'role.Character Design Cooperation, Animation Director': 'キャラクターデザイン協力、作画監督',
          'role.Character Designer, Chief Animation Director, Animation Director': 'キャラクターデザイン、総作画監督、作画監督',
          'role.Animation Character Designer, Chief Animation Director, Animation Director for Live Part, Animation Director, Assistant Animation Director': 'アニメーションキャラクターデザイン、総作画監督、ライブパート作画監督、作画監督、作画監督補佐',
          'role.Character Animation Director': 'キャラクター作画監督',
          'role.Monitor Works': 'モニターワークス',
          'role.3D Director': '3Dディレクター',
          'role.CG Director': 'CGディレクター',
          'role.Compositing Director': '撮影監督',
          'role.Assistant Compositing Director': '撮影監督補佐',
          'role.3D CGI': '3D CGI',
          'role.Compositing Staff': '撮影スタッフ',
          'role.Episode Director, Animation Director': '演出、 作画監督',
          'role.Episode Director, Storyboard, Animation Director, Plop Designer': '演出、 絵コンテ、 作画監督、 プロップデザイン',
          'role.Action Animation Director': 'アクション作画監督',
          'role.Plop Designer, Animation Director, Assistant Chief Animation Director': 'プロップデザイン、作画監督、総作画監督補佐',
          'role.Episode Director': '演出',
          'role.Chief Director, Episode Director': '総監督、 演出',
          'role.Director': '監督',
          'role.Director, Storyboard, Episode Director, Line Producer, Production Coordinator': '監督、 絵コンテ、 演出、 ラインプロデューサー、 制作進行',
          'role.Main Animator, Storyboard, Episode Director, Animation Director': 'メインアニメーター、 絵コンテ、 演出、 作画監督',
          'role.Key Animator, Animation Director': '原画、作画監督',
          'role.Character Designer, Chief Animation Director, Storyboard Artist, Episode Directror, Animation Director': 'キャラクターデザイン、 総作画監督、 絵コンテ、 演出、 作画監督',
          'role.Original Work, Scriptwriter': '原作、 脚本',
          'role.Series Organization, Scriptwriter': 'シリーズ構成、 脚本',
          'role.Storyboard': '絵コンテ',
          lc_en: 'EN',
          lc_ja: 'JP',
        },
      },
    },
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
