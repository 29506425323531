import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useLocale } from '../hooks/useLocale';
import styles from './NavigationLinks.module.css';

const MobileNavigation = () => {
  const location = useLocation();
  const locale = useLocale();
  const basePath = `/${locale.languageCode}`;

  return (
    <>
      <Navbar className={styles.mobileNav}>
        <div className={styles.container}>
          <Nav.Link
            className={
              location.pathname === `${basePath}/about`
                ? `${styles.activeNavLink}`
                : `${styles.navLink}`
            }
            href={`${basePath}/about`}
          >
            About
          </Nav.Link>
        </div>
        <div className={styles.container}>
          <Nav.Link
            className={
              location.pathname === `${basePath}/works`
                ? `${styles.activeNavLink}`
                : `${styles.navLink}`
            }
            href={`${basePath}/works`}
          >
            Works
          </Nav.Link>
        </div>
        <div className={styles.container}>
          <Nav.Link
            className={
              location.pathname === `${basePath}/gallery`
                ? `${styles.activeNavLink}`
                : `${styles.navLink}`
            }
            href={`${basePath}/gallery`}
          >
            Gallery
          </Nav.Link>
        </div>
        <div className={styles.container}>
          <Nav.Link
            className={
              location.pathname === `${basePath}/culture`
                ? `${styles.activeNavLink}`
                : `${styles.navLink}`
            }
            href={`${basePath}/culture`}
          >
            Culture
          </Nav.Link>
        </div>
        <div className={styles.container}>
          <Nav.Link
            className={
              location.pathname === `${basePath}/contact`
                ? `${styles.activeNavLink}`
                : `${styles.navLink}`
            }
            href="#contact"
          >
            Contact
          </Nav.Link>
        </div>
      </Navbar>
    </>
  );
};

export default MobileNavigation;
