/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '.';
import { Locale } from '../types';

export interface Identity{
  user: {
    t_id: string | null,
  }
  locale:Locale
}

/**
 * Initial state sets everything but locale to null, with the default language as process.env.REACT_APP_DEFAULT_LANGUAGE
 *
 */
const initialState: Identity = {
  user: {
    t_id: null,
  },
  locale: {
    languageCode: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
    countryCode: null,
    latitude: undefined,
    longitude: undefined,
    postalCode: undefined,
    countryRegion: undefined,
    city: undefined,
  },
};

/**
 * Redux slice under the 'auth' namespace handles user actions during the auth workflow
 * and state management
 *
 * @type {*}
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Called after the user successfully registers
     *
     * @param state current state
     * @param action with payload containing hydraded user response from API
     */
    setCountryDidChange: (state, { payload: { newCountryCode } }) => ({
      ...state,
      locale: {
        ...state.locale,
        countryCode: newCountryCode,
      },
    }),

    setLanguageDidChange: (state, { payload: { newLanguageCode } }) => ({
      ...state,
      locale: {
        ...state.locale,
        languageCode: newLanguageCode,
      },
    }),

    setLocaleDidChange: (state, { payload: { locale } }) => ({
      ...state,
      locale,
    }),

    setUserTrackingId: (state, { payload: { t_id } }) => ({
      ...state,
      user: {
        ...state.user,
        t_id,
      },
    }),
  },
});

/**
 * Export supported actions
 */
export const {
  setUserTrackingId,
  setCountryDidChange,
  setLanguageDidChange,
  setLocaleDidChange,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentLocale = (state: RootState) => (state.auth.locale);

export const selectCurrentIdentity = (state: RootState) => (state.auth);
